<main id="main" class="main">


    <div class="pagetitle">
      <h1 i18n>{{"Incidents" | translate }}</h1>
      <div>
        <button mat-mini-fab (click)="onNew()"> 
         <i class="bi bi-plus-lg"></i>
        </button>
      </div>
    </div>
  
  
  
    <div class="mat-elevation-z8 tablaPersonal">
      <mat-table [dataSource]="dataSource" matSort>
  
        <!-- Definir las columnas -->
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef class="header-table">
            <div mat-sort-header i18n>{{"ID" | translate }}</div>
            <div>
              <mat-form-field>
                <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                <input matInput [formControl]="idFilter" placeholder="" maxlength="40">
              </mat-form-field>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.id}}</mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="terminalId">
          <mat-header-cell *matHeaderCellDef class="header-table">
            <div mat-sort-header i18n>{{"Terminal ID" | translate }}</div>
            <div>
              <mat-form-field>
                <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                <input matInput [formControl]="terminalIdFilter" placeholder="" maxlength="40">
              </mat-form-field>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.terminalId}}</mat-cell>
        </ng-container>
  
  
        <ng-container matColumnDef="user">
            <mat-header-cell *matHeaderCellDef class="header-table">
              <div mat-sort-header i18n>{{"User" | translate }}</div>
              <div>
                <mat-form-field>
                  <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                  <input matInput [formControl]="userFilter" placeholder="" maxlength="40">
                </mat-form-field>
              </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.user}}</mat-cell>
          </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef class="header-table">
            <div mat-sort-header i18n>{{"Status" | translate }}</div>
            <div>
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                <mat-select [formControl]="statusFilter" placeholder="">
                  <mat-option value=""></mat-option>
                  <mat-option *ngFor="let status of statuses" [value]="status">{{ translations[status] }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">{{translations[element.status]}}</mat-cell>
        </ng-container>
  
  
        <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef class="header-table">
              <div mat-sort-header i18n>{{"Type" | translate }}</div>
              <div>
                <mat-form-field>
                  <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                  <mat-select [formControl]="typeFilter" placeholder="">
                    <mat-option value=""></mat-option>
                    <mat-option *ngFor="let type of types" [value]="type">{{ translations[type] }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">{{translations[element.type]}}</mat-cell>
          </ng-container>
    
    
          <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef class="header-table">
              <div mat-sort-header i18n>{{"Date" | translate }}</div>
              <div>
                <mat-form-field>
                  <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                  <input matInput [matDatepicker]="picker" [formControl]="dateFilter" placeholder="">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <button mat-icon-button matSuffix *ngIf="dateFilter.value" (click)="resetDateFilter()">
                    <mat-icon>clear</mat-icon>
                  </button>
                </mat-form-field>
              </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.date | date}}</mat-cell>
          </ng-container>
  
        <ng-container matColumnDef="resolutionDate">
          <mat-header-cell *matHeaderCellDef class="header-table">
            <div mat-sort-header i18n>{{"Resolution date" | translate }}</div>
            <div>
              <mat-form-field>
                <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                <input matInput [matDatepicker]="picker" [formControl]="resolutionDateFilter" placeholder="">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <button mat-icon-button matSuffix *ngIf="resolutionDateFilter.value" (click)="resetResolutionDateFilter()">
                  <mat-icon>clear</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.resolutionDate | date}}</mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="resolution">
            <mat-header-cell *matHeaderCellDef class="header-table">
              <div mat-sort-header i18n>{{"Resolution" | translate }}</div>
              <div>
                <mat-form-field>
                  <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                  <input matInput [formControl]="resolutionFilter" placeholder="" maxlength="40">
                </mat-form-field>
              </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.resolution}}</mat-cell>
          </ng-container>
    
    
          <ng-container matColumnDef="accions">
            <mat-header-cell *matHeaderCellDef >
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button (click)="openDialog(element)" mat-icon-button color="primary" *ngIf="!element.resolutionDate">
                <i class="bi bi-journal-text"></i>
                </button>
            </mat-cell>
          </ng-container>
  
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
  
      <mat-paginator [length]="totalElements" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="loadIncidence($event)">
      </mat-paginator>
    </div>
  </main>
  