import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { Authorization } from 'src/app/models/authorization.model';
import { EventAuthorization } from 'src/app/models/eventAuthorization';
import { FilterAndPagination } from 'src/app/models/FilterAndPagination.model';
import { Page } from 'src/app/models/page.model';
import { handleError } from 'src/app/shared/util/handelError';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }
  

  findAuthorizations(filterAndPagination: FilterAndPagination): Observable<Page<Authorization>> {
    return this.http.post<Page<Authorization>>(`${this.apiUrl}/authorization`, filterAndPagination)
      .pipe(
        catchError(handleError<Page<Authorization>>('authorization', { content: [], totalPages: 0, totalElements: 0, number: 0, size: 0 }))
      );
  }

  findEventAuthorizations(eventAuthorizationId: string): Observable<EventAuthorization[]> {


    return this.http.get<EventAuthorization[]>(`${this.apiUrl}/event/${eventAuthorizationId}`)
      .pipe(
        catchError(handleError<EventAuthorization[]>('eventAthorization', []))
      );
  }

}
