import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UsersProfileComponent } from './pages/users-profile/users-profile.component';
import { TerminalComponent } from './pages/views/terminal/components/terminal-list/terminal.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { AuthGuard } from './auth/services/auth-guard.service';
import { TermsConditionsComponent } from './pages/static-pages/terms-conditions/terms-conditions.component';
import { ClientComponent } from './pages/views/client/components/client-list/client.component';
import { TariffComponent } from './pages/views/tariff/components/tariff-list/tariff.component';
import { CpoComponent } from './pages/views/cpo/components/cpo-list/cpo.component';
import { ConnectorComponent } from './pages/views/connector/components/connector-list/connector.component';
import { CdrComponent } from './pages/views/cdr/components/cdr-list/cdr.component';
import { EvseComponent } from './pages/views/evse/components/evse-list/evse.component';
import { LocationsComponent } from './pages/views/locations/components/locations/locations.component';
import { IncidentsComponent } from './pages/views/incidents/components/incidence-list/incidents.component';
import { AuthorizationComponent } from './pages/views/authorization/components/authorization-list/authorization.component';

const routes: Routes = [
  { path: '', redirectTo: 'welcome', pathMatch: 'full' },
  { path: 'welcome', component: WelcomeComponent },
  // { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  // { path: 'organizations', component: OrganizationsComponent, canActivate: [AuthGuard], data: {authorg:'merchantsApi',authkey:'organization'} },
  // { path: 'merchants', component: MerchantsComponent, canActivate: [AuthGuard], data: {authorg:'merchantsApi',authkey:'merchant'} },
  
  // { path: 'region', component: RegionComponent, canActivate: [AuthGuard], data: {authorg:'merchantsApi',authkey:'merchant'} },
  // { path: 'division', component: DivisionComponent, canActivate: [AuthGuard], data: {authorg:'merchantsApi',authkey:'merchant'} },


  // { path: 'users', component: UsersComponent, canActivate: [AuthGuard], data: {authorg:'authserver',authkey:'user'} },
  // { path: 'rols', component: RolComponent, canActivate: [AuthGuard], data: {authorg:'authserver',authkey:'role'} },
  // { path: 'groups', component: GroupsComponent, canActivate: [AuthGuard], data: {authorg:'authserver',authkey:'group'} },




  // emsp
  { path: 'client', component: ClientComponent, canActivate: [AuthGuard], data: {authorg:'emspapi',authkey:'client'} },
  { path: 'tariff', component: TariffComponent, canActivate: [AuthGuard], data: {authorg:'emspapi',authkey:'tariff'} },
  { path: 'managementCpo', component: CpoComponent, canActivate: [AuthGuard], data: {authorg:'emspapi',authkey:'credential'} },
  { path: 'connector', component: ConnectorComponent, canActivate: [AuthGuard], data: {authorg:'emspapi',authkey:'connector'} },
  { path: 'cdr', component: CdrComponent, canActivate: [AuthGuard], data: {authorg:'emspapi',authkey:'cdr'} },
  { path: 'authorization', component: AuthorizationComponent, canActivate: [AuthGuard], data: {authorg:'emspapi',authkey:'authorization'} },
  { path: 'evse', component: EvseComponent, canActivate: [AuthGuard], data: {authorg:'emspapi',authkey:'evse'} },
  { path: 'terminal', component: TerminalComponent, canActivate: [AuthGuard], data: {authorg:'emspapi',authkey:'terminal'} },
  { path: 'location', component: LocationsComponent, canActivate: [AuthGuard], data: {authorg:'emspapi',authkey:'location'} },
  { path: 'location/:locationId', component: LocationsComponent, canActivate: [AuthGuard], data: {authorg:'emspapi',authkey:'location'} },
  { path: 'incidence', component: IncidentsComponent, canActivate: [AuthGuard], data: {authorg:'emspapi',authkey:'incidence'} },



  { path: 'terms-conditions', component: TermsConditionsComponent },
  { path: 'user-profile', component: UsersProfileComponent },
  { path: '**', redirectTo: 'welcome' }, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
