import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError} from 'rxjs';
import { CDR } from 'src/app/models/cdr.model';
import { FilterAndPagination } from 'src/app/models/FilterAndPagination.model';
import { Page } from 'src/app/models/page.model';
import { handleError } from 'src/app/shared/util/handelError';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class CdrService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }
  

  findCdrs(filterAndPagination: FilterAndPagination): Observable<Page<CDR>> {
    return this.http.post<Page<CDR>>(`${this.apiUrl}/cdr`, filterAndPagination)
      .pipe(
        catchError(handleError<Page<CDR>>('cdr', { content: [], totalPages: 0, totalElements: 0, number: 0, size: 0 }))
      );
  }

}