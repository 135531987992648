<main id="main" class="main">


    <div class="pagetitle">
      <h1  i18n>{{"Authorizations" | translate }}</h1>  
  </div>
    
      <div class="mat-elevation-z8 tablaPersonal">
        <mat-table [dataSource]="dataSource" matSort>

            <!-- Definir las columnas -->
            <ng-container matColumnDef="partyId">
              <mat-header-cell *matHeaderCellDef class="header-table">
                <div mat-sort-header i18n>{{"Party ID" | translate }}</div>  
                <div>
                  <mat-form-field>
                    <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                    <input matInput [formControl]="partyIdFilter" placeholder="" maxlength="40">
                  </mat-form-field>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.partyId}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="countryCode">
              <mat-header-cell *matHeaderCellDef class="header-table">
                <div mat-sort-header i18n>{{"Country code" | translate }}</div>  
                <div>
                  <mat-form-field>
                    <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                    <input matInput [formControl]="countryCodeFilter" placeholder="" maxlength="40">
                  </mat-form-field>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.countryCode}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="evseUid">
                <mat-header-cell *matHeaderCellDef class="header-table">
                  <div mat-sort-header i18n>{{"Charging point Uid" | translate }}</div>  
                  <div>
                    <mat-form-field>
                      <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                      <input matInput [formControl]="evseUidFilter" placeholder="" maxlength="40">
                    </mat-form-field>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.evseUid}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="connectorId">
                <mat-header-cell *matHeaderCellDef class="header-table">
                  <div mat-sort-header i18n>{{"Connector ID" | translate }}</div>  
                  <div>
                    <mat-form-field>
                      <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                      <input matInput [formControl]="connectorIdFilter" placeholder="" maxlength="40">
                    </mat-form-field>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.connectorId}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="authorizationReference">
                <mat-header-cell *matHeaderCellDef class="header-table">
                  <div mat-sort-header i18n>{{"Authorization reference" | translate }}</div>  
                  <div>
                    <mat-form-field>
                      <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                      <input matInput [formControl]="authorizationReferenceFilter" placeholder="" maxlength="40">
                    </mat-form-field>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.authorizationReference}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef class="header-table">
                  <div mat-sort-header i18n>{{"Status" | translate }}</div>  
                  <div>
                    <mat-form-field>
                      <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                      <input matInput [formControl]="statusFilter" placeholder="" maxlength="40">
                    </mat-form-field>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.status}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="lastUpdated">
                <mat-header-cell *matHeaderCellDef class="header-table">
                  <div mat-sort-header i18n>{{"Last Updated" | translate }}</div>
                  <div>
                    <mat-form-field>
                      <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                      <input matInput [matDatepicker]="picker" [formControl]="lastUpdatedFilter" placeholder="">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                      <button mat-icon-button matSuffix *ngIf="lastUpdatedFilter.value" (click)="resetLastUpdatedFilter()">
                        <mat-icon>clear</mat-icon>
                      </button>
                    </mat-form-field>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.lastUpdated | date}}</mat-cell>
              </ng-container>
              


              
              <ng-container matColumnDef="accions">
                <mat-header-cell *matHeaderCellDef >
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <button (click)="openDialog(element)" mat-icon-button color="primary">
                    <i class="bi bi-journal-text"></i>
                    </button>
                </mat-cell>
              </ng-container>
            <!-- Definir el header y rows -->
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
          
          <!-- Paginador -->
          <mat-paginator [length]="totalElements"
                         [pageSize]="pageSize"
                         [pageSizeOptions]="pageSizeOptions"
                         (page)="loadAuthorizations($event)">
          </mat-paginator>
      </div>
      </main>
  