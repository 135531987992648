import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Terminal } from 'src/app/models/terminal.model';
import { getAllEvseUids } from 'src/app/shared/util/util';

@Component({
  selector: 'app-terminal-details',
  templateUrl: './terminal-details.component.html',
  styleUrls: ['./terminal-details.component.css']
})
export class TerminalDetailsComponent {


  constructor(
    public dialogRef: MatDialogRef<TerminalDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Terminal
  ) {
  }

  evsesIds: string[] = [];


  ngOnInit(): void {
  this.evsesIds = getAllEvseUids(this.data.services);

}
  
  onClose(): void {
    this.dialogRef.close();
  }
}