<section class="section">
    <div class="pagetitle pagetitle-modal">
        <h1 i18n>Detalles de Tarifas - Connector {{data.connectorId}}</h1>  
    </div>
    <mat-tab-group>
        <mat-tab label="Tariff list">
            <div class="row ajuste-contenedor-tab">
                <app-tariff  [data]="data.tariffIds"></app-tariff>
            </div>
        </mat-tab>
    </mat-tab-group>
</section>

