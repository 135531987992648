import { AfterViewInit, Component, Input, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Tariff } from 'src/app/models/tariffs.model';
import { environment } from 'src/environments/environment';
import { TariffService } from '../../services/tariff.service';
import { Page } from 'src/app/models/page.model';
import { UtilService } from 'src/app/services/util.service';
import { FilterAndPagination } from 'src/app/models/FilterAndPagination.model';

@Component({
  selector: 'app-tariff',
  templateUrl: './tariff.component.html',
  styleUrls: ['./tariff.component.css']
})
export class TariffComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['partyId', 'countryCode', 'currency', 'minPrice', 'maxPrice', 'startDateTime', 'endDateTime'];
  dataSource = new MatTableDataSource<Tariff>();
  types: string[] = [];
  currencies: string[] = [];
  modelTypes: string[] = [];
  totalElements: number = 0; // Total de elementos para paginación
  pageSize: number = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;

  partyIdFilter = new FormControl();
  countryCodeFilter = new FormControl();
  currencyFilter = new FormControl();
  typeFilter = new FormControl();
  minPriceFilter = new FormControl();
  endDateFilter = new FormControl();
  startDateFilter = new FormControl();
  maxPriceFilter = new FormControl();
  energyMixFilter = new FormControl();
  modelTypeFilter = new FormControl();

  filterAndPagination: FilterAndPagination = {
    orderDirection: 'DESC',
    orderColumn: 'partyId', 
    filterGeneric: [],
    page: 0,
    limit: 0,
    pagIndexStart: 0,
    pagIndexEnd: environment.defaultPaginacion
  };

  @Input() data?: string[];
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  constructor(
    private tariffService: TariffService, 
    private utilService: UtilService, 
    private cdr: ChangeDetectorRef // Para detectar cambios si es necesario
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.applyFilters();
    }
    
    // Cargar datos iniciales
    this.loadTariffs();
    this.loadTypes();

    // Suscribirse a los cambios en los filtros
    this.partyIdFilter.valueChanges.subscribe(() => this.applyFilters());
    this.countryCodeFilter.valueChanges.subscribe(() => this.applyFilters());
    this.currencyFilter.valueChanges.subscribe(() => this.applyFilters());
    this.typeFilter.valueChanges.subscribe(() => this.applyFilters());
    this.minPriceFilter.valueChanges.subscribe(() => this.applyFilters());
    this.startDateFilter.valueChanges.subscribe(() => this.applyFilters());
    this.endDateFilter.valueChanges.subscribe(() => this.applyFilters());
    this.maxPriceFilter.valueChanges.subscribe(() => this.applyFilters());
    this.energyMixFilter.valueChanges.subscribe(() => this.applyFilters());
    this.modelTypeFilter.valueChanges.subscribe(() => this.applyFilters());
  }

  ngAfterViewInit(): void {
    // Usar ChangeDetectorRef para asegurar que paginator y sort están inicializados
    this.cdr.detectChanges();

    // Asignar paginador y sorter a la dataSource
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // Suscribirse al cambio de ordenación
    this.sort.sortChange.subscribe((sortState: Sort) => {
      this.filterAndPagination.orderColumn = sortState.active;
      this.filterAndPagination.orderDirection = sortState.direction ? sortState.direction.toUpperCase() : 'ASC';
      this.loadTariffs();
    });
  }
  
  applyFilters(): void {
    const filters = [];

    if (this.partyIdFilter.value) {
      filters.push({ column: 'partyId', value: this.partyIdFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.countryCodeFilter.value) {
      filters.push({ column: 'countryCode', value: this.countryCodeFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.currencyFilter.value) {
      filters.push({ column: 'currency', value: this.currencyFilter.value, operation: 'LIKE', type: 'STRING' });
    }

    if (this.minPriceFilter.value) {
      filters.push({ column: 'minPrice', value: this.minPriceFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.startDateFilter.value) {
      filters.push({ column: 'startDateTime', value: this.utilService.formatDateToISOString(this.startDateFilter.value), operation: 'GREATER_THAN_OR_EQUAL', type: 'DATE' });
    }
    if (this.endDateFilter.value) {
      filters.push({ column: 'endDateTime', value: this.utilService.formatDateToISOString(this.endDateFilter.value), operation: 'LESS_THAN_OR_EQUAL', type: 'DATE' });
    }
    if (this.maxPriceFilter.value) {
      filters.push({ column: 'maxPrice', value: this.maxPriceFilter.value, operation: 'LIKE', type: 'STRING' });
    }

    // Agregar filtro de tariffsIds si existe connectorData
    if (this.data) {
      filters.push({ column: 'tariffId', value: this.data.join(','), operation: 'IN', type: 'STRING' });
    }

    this.filterAndPagination.filterGeneric = filters;

    this.loadTariffs();
  }

  loadTariffs(event?: PageEvent): void {
    this.filterAndPagination.page = event ? event.pageIndex : 0;
    this.filterAndPagination.limit = event ? event.pageSize : this.pageSize;
    this.filterAndPagination.pagIndexStart = this.filterAndPagination.page * this.filterAndPagination.limit;
    this.filterAndPagination.pagIndexEnd = this.filterAndPagination.pagIndexStart + this.filterAndPagination.limit;

    this.tariffService.findTariffs(this.filterAndPagination).subscribe((page: Page<Tariff>) => {
      this.dataSource.data = page.content;
      this.totalElements = page.totalElements;
      this.pageSize = page.size;

      // Verificar que paginator no sea undefined
      if (this.paginator) {
        this.paginator.length = this.totalElements;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
      }
    });
  }

  loadTypes(): void {
    this.tariffService.getTypesTariffs().subscribe((types: string[]) => {
      this.types = types;
    });
  }

  resetStartDateFilter(): void {
    this.startDateFilter.setValue(null);
  }

  resetEndDateFilter(): void {
    this.endDateFilter.setValue(null);
  }

}
