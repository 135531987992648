<main id="main" class="main">


  <div class="pagetitle">
    <h1 i18n>{{"Charging points" | translate }}</h1>
  </div>



  <div class="mat-elevation-z8 tablaPersonal">
    <mat-table [dataSource]="dataSource" matSort>

      <!-- Definir las columnas -->
      <ng-container matColumnDef="uid">
        <mat-header-cell *matHeaderCellDef class="header-table">
          <div mat-sort-header i18n>{{"UID" | translate }}</div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
              <input matInput [formControl]="uidFilter" placeholder="" maxlength="40">
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.uid}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="floorLevel">
        <mat-header-cell *matHeaderCellDef class="header-table">
          <div mat-sort-header i18n>{{"Floor Level" | translate }}</div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
              <input matInput [formControl]="floorLevelFilter" placeholder="" maxlength="40">
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.floorLevel}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="latitude">
        <mat-header-cell *matHeaderCellDef class="header-table">
          <div mat-sort-header i18n>{{"Latitude" | translate }}</div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
              <input matInput [formControl]="latitudeFilter" placeholder="" maxlength="40">
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.latitude}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="longitude">
        <mat-header-cell *matHeaderCellDef class="header-table">
          <div mat-sort-header i18n>{{"Longitude" | translate }}</div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
              <input matInput [formControl]="longitudeFilter" placeholder="" maxlength="40">
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.longitude}}</mat-cell>
      </ng-container>


      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef class="header-table">
          <div mat-sort-header i18n>{{"Status" | translate }}</div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
              <mat-select [formControl]="statusFilter" placeholder="">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let status of statuses" [value]="status">{{ translations[status] }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{translations[element.status]}}</mat-cell>
      </ng-container>



      <ng-container matColumnDef="connectors">
        <mat-header-cell *matHeaderCellDef class="header-table">
          <div mat-sort-header i18n>{{"Connectors" | translate }}</div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
              <input matInput [formControl]="connectorsFilter" placeholder="" maxlength="40">
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button [matTooltip]="('Connector ID' | translate) + ': ' + getConnectorIds(element.connectors)"
            aria-label="Button that displays a tooltip when focused or hovered over" mat-icon-button color="primary">
            <i class="bi bi-plug"></i> </button>

        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastUpdated">
        <mat-header-cell *matHeaderCellDef class="header-table">
          <div mat-sort-header i18n>{{"Last Updated" | translate }}</div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
              <input matInput [matDatepicker]="picker" [formControl]="lastUpdatedFilter" placeholder="">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <button mat-icon-button matSuffix *ngIf="lastUpdatedFilter.value" (click)="resetLastUpdatedFilter()">
                <mat-icon>clear</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.lastUpdated | date}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="modelType">
        <mat-header-cell *matHeaderCellDef class="header-table">
          <div mat-sort-header i18n>{{"Model type" | translate }}</div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
              <mat-select [formControl]="modelTypeFilter" placeholder="">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let modelType of modelTypes" [value]="modelType">{{ modelType }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.modelType}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="accions">
        <mat-header-cell *matHeaderCellDef>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button (click)="navigateToLocations(element.locationId)" mat-icon-button color="primary">
            <i class="bi bi-geo"></i>
          </button>

          <button (click)="openDialog(element)" mat-icon-button color="primary">
            <i class="bi bi-journal-text"></i>
          </button>

        </mat-cell>
      </ng-container>

      <!-- Definir el header y rows -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <!-- Paginador -->
    <mat-paginator [length]="totalElements" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
      (page)="loadEvses($event)">
    </mat-paginator>
  </div>
</main>
