import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, shareReplay } from 'rxjs';
import { Connector } from 'src/app/models/connector.model';
import { Page } from 'src/app/models/page.model';
import { handleError } from 'src/app/shared/util/handelError';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class ConnectorService {

  private connectorFormats$!: Observable<string[]>;
  private connectorTypes$!: Observable<string[]>;
  private powerTypes$!: Observable<string[]>;
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
    this.initialize();
  }
  
  findConnectors(connectorRequest: Connector | {}, offset: number = 0, limit?: number): Observable<Page<Connector>> {
    let params = new HttpParams()
      .set('offset', offset.toString());
    
    if (limit !== undefined) {
      params = params.set('limit', limit.toString());
    }

    return this.http.post<Page<Connector>>(`${this.apiUrl}/connector`, connectorRequest, { params })
      .pipe(
        catchError(handleError<Page<Connector>>('findConnectors', { content: [], totalPages: 0, totalElements: 0, number: 0, size: 0 }))
      );
  }


  private initialize(): void {
    this.connectorFormats$ = this.http.get<string[]>(`${this.apiUrl}/connector/connector-formats`)
      .pipe(
        catchError(handleError<string[]>('fetching connector formats', [])),
        shareReplay(1)
      );

      
    this.powerTypes$ = this.http.get<string[]>(`${this.apiUrl}/connector/power-types`)
    .pipe(
      catchError(handleError<string[]>('fetching connector power types', [])),
      shareReplay(1)
    );

    this.connectorTypes$ = this.http.get<string[]>(`${this.apiUrl}/connector/connector-types`)
      .pipe(
        catchError(handleError<string[]>('fetching connector types', [])),
        shareReplay(1)
      );
  }

  getConnectorFormats(): Observable<string[]> {
    return this.connectorFormats$;
  }
  getConnectorTypes(): Observable<string[]> {
    return this.connectorTypes$;
  }
  getPowerTypes(): Observable<string[]> {
    return this.powerTypes$;
  }
}
