import { NgModule,CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { CommonModule, registerLocaleData } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/legacy-form-field';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import { MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { UsersProfileComponent } from './pages/users-profile/users-profile.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialExampleModule } from './material.module';
import { TerminalComponent } from './pages/views/terminal/components/terminal-list/terminal.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { ChartTxDonutComponent } from './shared/components/chart-tx-donut/chart-tx-donut.component';
import { ChartModelComponent } from './shared/components/chart-model/chart-model.component';
import { ChartTop5MerchantComponent } from './shared/components/chart-top5-merchant/chart-top5-merchant.component';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NgxNumericRangeFormFieldModule } from './shared/components/numeric-range/ngx-numeric-range-form-field.module';
import { AngularDualListBoxModule } from './shared/components/dual-listbox/angular-dual-listbox.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ChartTxHeatmapComponent } from './shared/components/chart-tx-heatmap/chart-tx-heatmap.component';
import { AuthModule } from './auth/authentication/auth.module';
import { TopbarComponent } from './pages/welcome/topbar/topbar.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { AuthRestUrl, EmspRestUrl } from './shared/util/constants';
import { AUTH_URL,EMSP_URL } from './auth/authentication/app.oauth-tokens';
import { UnauthorizedInterceptor } from './auth/interceptors/unauthorized.interceptor';
import { Router } from '@angular/router';
import { AuthService } from './auth/services/auth.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';
import { TermsConditionsComponent } from './pages/static-pages/terms-conditions/terms-conditions.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { BarJerarquiaComponent } from './layouts/bar-jerarquia/bar-jerarquia.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { CustomMatPaginatorIntl } from './shared/util/customMatPaginatorIntl ';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { CountryPipe } from './shared/util/pipes/country.pipe';
import localeEs from '@angular/common/locales/es';
import { ChartCompareTipesByHourComponent } from './shared/components/chart-compare-tipes-by-hour/chart-compare-tipes-by-hour.component';
import { ClientComponent } from './pages/views/client/components/client-list/client.component';
import { TariffComponent } from './pages/views/tariff/components/tariff-list/tariff.component';
import { CpoComponent } from './pages/views/cpo/components/cpo-list/cpo.component';
import { ConnectorComponent } from './pages/views/connector/components/connector-list/connector.component';
import { CdrComponent } from './pages/views/cdr/components/cdr-list/cdr.component';
import { CurrencySymbolPipe } from './shared/util/pipes/currency-symbol.pipe';
import { EvseComponent } from './pages/views/evse/components/evse-list/evse.component';
import { CdrDetailsComponent } from './pages/views/cdr/components/cdr-details/cdr-details.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AuthorizationDetailsComponent } from './pages/views/authorization/components/authorization-details/authorization-details.component';
import { ConnectorTariffsComponent } from './pages/views/connector/components/connector-tariffs/connector-tariffs.component';
import { EvseConnectorComponent } from './pages/views/evse/components/evse-connector-tariff/evse-connector-tariff.component';
import { TerminalDetailsComponent } from './pages/views/terminal/components/terminal-details/terminal-details.component';
import { TimelineComponent } from './shared/components/timeline/timeline.component';
import { MapComponent } from './shared/components/map/map.component';
import { LocationsComponent } from './pages/views/locations/components/locations/locations.component';
import { IncidentsComponent } from './pages/views/incidents/components/incidence-list/incidents.component';
import { IncidenceDetailsComponent } from './pages/views/incidents/components/incidence-details/incidence-details.component';
import { AddTerminalComponent } from './pages/views/terminal/components/add-terminal/add-terminal.component';
import { AuthorizationComponent } from './pages/views/authorization/components/authorization-list/authorization.component';
import { NewCredentialsComponent } from './pages/views/cpo/components/new-credentials/new-credentials.component';
import { NewClientComponent } from './pages/views/client/components/new-client/new-client.component';
import { CdrEvseConnectorTariffComponent } from './pages/views/cdr/components/cdr-evse-connector-tariff/cdr-evse-connector-tariff.component';
registerLocaleData(localeEs, 'es')

const customNotifierOptions: NotifierOptions = {
  position: {
      horizontal: {
          position: 'right',
          distance: 12
      },
      vertical: {
          position: 'top',
          distance: 12,
          gap: 10
      }
  },
  theme: 'material',
  behaviour: {
      autoHide: 5000,
      onClick: false,
      onMouseover: 'pauseAutoHide',
      showDismissButton: true,
      stacking: 4
  },
  animations: {
      enabled: true,
      show: {
          preset: 'slide',
          speed: 300,
          easing: 'ease'
      },
      hide: {
          preset: 'fade',
          speed: 300,
          easing: 'ease',
          offset: 50
      },
      shift: {
          speed: 300,
          easing: 'ease'
      },
      overlap: 150
  }
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    TopbarComponent,
    SidebarComponent,
    TerminalComponent,
    ConnectorComponent,
    ClientComponent,
    CdrEvseConnectorTariffComponent,
    CdrComponent,
    CdrDetailsComponent,
    NewClientComponent,
    AuthorizationComponent,
    EvseComponent,
    EvseConnectorComponent,
    TimelineComponent,
    MapComponent,
    NewCredentialsComponent,
    LocationsComponent,
    TerminalDetailsComponent,
    TariffComponent,
    ConnectorTariffsComponent,
    CpoComponent,
    AuthorizationDetailsComponent,
    IncidenceDetailsComponent,
    AddTerminalComponent,
    IncidentsComponent,
    WelcomeComponent,
    BarJerarquiaComponent,
    TermsConditionsComponent,
    ChartTxDonutComponent,
    ChartTop5MerchantComponent,
    ChartModelComponent,
    ChartTxHeatmapComponent,
    ChartCompareTipesByHourComponent,
    ConfirmDialogComponent,
    UsersProfileComponent,
    CountryPipe,
    CurrencySymbolPipe  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    NotifierModule.withConfig(customNotifierOptions),
    ReactiveFormsModule,
    AppRoutingModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule, 
    BrowserAnimationsModule,
    HttpClientModule,
    MatNativeDateModule,
    MatDialogModule,
    MatTableExporterModule,
    MaterialExampleModule,
    AngularDualListBoxModule   ,
    NgxNumericRangeFormFieldModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    FontAwesomeModule,
    NgApexchartsModule,
    AuthModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}},
    { provide: AUTH_URL, useValue: AuthRestUrl },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: function(router: Router, authService: AuthService) {
        return new UnauthorizedInterceptor(router,authService);
      },
      multi: true,
      deps: [Router, AuthService]
   },
    { provide: EMSP_URL, useValue: EmspRestUrl  },
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl},
    { provide: MAT_DATE_LOCALE, useValue : 'en-US' },
    // { provide: MAT_DATE_FORMATS, useFactory: ()=> {
    //   return navigator.language === 'en-US' ? US_DATE_FORMATS : EU_DATE_FORMATS;
    // }},
    // { provide: LOCALE_ID, useValue: 'es-Es' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far, fab);
  }
 }
 export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
