<main id="main" class="main">


    <div class="pagetitle">
      <h1  i18n>{{"CPO management" | translate }} </h1>  
      <div>
        <button mat-mini-fab (click)="onNew()"> 
         <i class="bi bi-plus-lg"></i>
        </button>
      </div>
  </div>
    
    
      
      <div class="mat-elevation-z8 tablaPersonal">
        <mat-table [dataSource]="dataSource" matSort>

            <!-- Definir las columnas -->
            <ng-container matColumnDef="partyId">
              <mat-header-cell *matHeaderCellDef class="header-table">
                <div mat-sort-header i18n>{{"Party ID" | translate }}</div>
                <div>
                  <mat-form-field>
                    <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                    <input matInput [formControl]="partyIdFilter" placeholder="" maxlength="40">
                  </mat-form-field>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.partyId}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="countryCode">
              <mat-header-cell *matHeaderCellDef class="header-table">
                <div mat-sort-header i18n>{{"Country code" | translate }}</div>
                <div>
                  <mat-form-field>
                    <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                    <input matInput [formControl]="countryCodeFilter" placeholder="" maxlength="40">
                  </mat-form-field>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.countryCode}}</mat-cell>
            </ng-container>

            
              <ng-container matColumnDef="token">
                <mat-header-cell *matHeaderCellDef class="header-table">
                  <div mat-sort-header i18n>{{"Token" | translate }} </div>
                  <div>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.token}}</mat-cell>
              </ng-container>

            <ng-container matColumnDef="role">
              <mat-header-cell *matHeaderCellDef class="header-table">
                <div mat-sort-header i18n>{{"Role" | translate }}</div>
                <div>
                  <mat-form-field>
                    <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                    <mat-select [formControl]="roleFilter" placeholder="">
                      <mat-option value=""></mat-option>
                      <mat-option *ngFor="let role of roles" [value]="role">{{ role }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.role}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef class="header-table">
                  <div mat-sort-header i18n>{{"Business name" | translate }}</div>
                  <div>
                    <mat-form-field>
                      <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                      <input matInput [formControl]="nameFilter" placeholder="" maxlength="40">
                    </mat-form-field>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.name}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="accions">
                <mat-header-cell *matHeaderCellDef >
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <button (click)="openDialog(element)" mat-icon-button color="primary">
                    <i class="bi bi-journal-text"></i>
                    </button>
                </mat-cell>
              </ng-container>

            <!-- Definir el header y rows -->
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
          
          <!-- Paginador -->
          <mat-paginator [length]="totalElements"
                         [pageSize]="pageSize"
                         [pageSizeOptions]="pageSizeOptions"
                         (page)="loadCredentials($event)">
          </mat-paginator>
      </div>
      </main>
  