import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CDR } from 'src/app/models/cdr.model';

@Component({
  selector: 'app-cdr-details',
  templateUrl: './cdr-details.component.html',
  styleUrls: ['./cdr-details.component.css']
})
export class CdrDetailsComponent {
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CdrDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CDR
  ) {
  }

  cdrForm!: FormGroup;

  ngOnInit() {
    this.cdrForm = this.fb.group({
      CDRId: [{ value: this.data.cdrId, disabled: true }],
      partyId: [{ value: this.data.partyId, disabled: true }],
      countryCode: [{ value: this.data.countryCode, disabled: true }],
      evseId: [{ value: this.data.cdrLocation.evseId, disabled: true }],
      ConnectorId: [{ value: this.data.cdrLocation.connectorId, disabled: true }],
      authMethod: [{ value: this.data.authMethod, disabled: true }],
      authReference: [{ value: this.data.authorizationReference, disabled: true }],
      totalCost: [{ value: this.data.totalCost?.inclVat, disabled: true }],
      totalFixedCost: [{ value: this.data.totalFixedCost?.inclVat, disabled: true }],
      totalEnergyCost: [{ value: this.data.totalEnergyCost?.inclVat, disabled: true }],
      totalTimeCost: [{ value: this.data.totalTimeCost?.inclVat, disabled: true }],
      totalParkingCost: [{ value: this.data.totalParkingCost?.inclVat, disabled: true }],
      totalReservationCost: [{ value: this.data.totalReservationCost?.inclVat, disabled: true }],
      currency: [{ value: this.data.currency, disabled: true }],
      totalEnergy: [{ value: this.data.totalEnergy, disabled: true }],
      totalTime: [{ value: this.data.totalTime, disabled: true }],
      totalParkingTime: [{ value: this.data.totalParkingTime, disabled: true }],
      remark: [{ value: this.data.remark, disabled: true }],
      invoiceReferenceId: [{ value: this.data.invoiceReferenceId, disabled: true }],
      hasCredit: [{ value: this.data.credit, disabled: true }],
      creditReferenceId: [{ value: this.data.creditReferenceId, disabled: true }],
      homeChargingCompensation: [{ value: this.data.homeChargingCompensation, disabled: true }]
    });
  
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
 