import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { AuthService } from 'src/app/auth/services/auth.service';
import { FilterAndPagination } from 'src/app/models/FilterAndPagination.model';
import { Merchants } from 'src/app/models/merchants.model';
import { Organization } from 'src/app/models/organization.model';
import { OrganizationsService } from 'src/app/pages/views/organizations/services/organizations.service';
import { DomainService } from 'src/app/services/domain.service';
import { UtilService } from 'src/app/services/util.service';
import { TypeJerarquia, TypeJerarquiaText } from 'src/app/shared/util/constants';

@Component({
  selector: 'app-bar-jerarquia',
  templateUrl: './bar-jerarquia.component.html',
  styleUrls: ['./bar-jerarquia.component.css']
})
export class BarJerarquiaComponent implements OnInit {

  @Input() nivel!: string;

  registerForm!: FormGroup;

  verRegion: boolean = false;
  verDivision: boolean = false;
  verMerchant: boolean = false;

  readonlyOrganization: boolean = false;
  readonlyRegion: boolean = false;
  readonlyDivision: boolean = false;
  readonlyMerchant: boolean = false;

  listaOrg: Organization[] = [];
  listaRegion: Merchants[] = [];
  listaDivision: Merchants[] = [];
  listaMerchant: Merchants[] = [];

  selectedOrg: Organization = new Organization();
  selectedInicializacion: Organization = new Organization();
  selectedRegion: Merchants = new Merchants();
  selectedDivision: Merchants = new Merchants();
  selectedMerchant: Merchants = new Merchants();

  filterAndPagination: FilterAndPagination = {
    orderDirection: 'DESC',
    orderColumn: 'name',
    filterGeneric: [],
  };

  constructor(
    private formBuilder: FormBuilder,
    private authServer: AuthService,
    private organizationService: OrganizationsService,
    private commonService: UtilService,
    private domainService: DomainService,

  ) { }

  async cargar() {
    try {
      const srOrg = await this.organizationService.findOrganizations(this.filterAndPagination).toPromise();
      if (srOrg) {
        this.listaOrg = srOrg.content;
        if (this.listaOrg.length === 1) {
          this.selectedOrg = this.listaOrg[0];
          this.selectedInicializacion = this.selectedOrg;
          this.readonlyOrganization = true;
          localStorage.setItem('ORGANIZATION', JSON.stringify(this.listaOrg[0]));
          this.authServer.jerarquiaSearchClicked.next("");
        } else {
          const index = this.findOrgIndex();
          if (index !== -1) {
            this.selectedOrg = this.listaOrg[index];
            this.selectedInicializacion = this.selectedOrg;
            localStorage.setItem('ORGANIZATION', JSON.stringify(this.selectedOrg));
            this.authServer.jerarquiaSearchClicked.next("");
          }
        }
      }
    } catch (error) {
      console.error('Error fetching organizations:', error);
    }
  }

  findOrgIndex(): number {
    const hostname = window.location.hostname;
    const prefix = hostname.split(/[.-]/)[0].toLowerCase();
    const index = this.findIndexByCriteria(prefix);
    if (index !== -1) {
      return index;
    }
    const tokenClaimsStr = localStorage.getItem('id_token_claims_obj');
    if (tokenClaimsStr) {
      const tokenClaims = JSON.parse(tokenClaimsStr).domain.toLowerCase();
      return this.findIndexByCriteria(tokenClaims);
    }
    return -1;
  }

  findIndexByCriteria(criteria: string): number {
    return this.listaOrg.findIndex(org => org.domainRoot.toLowerCase().includes(criteria));
  }

  async ngOnInit(): Promise<void> {
    this.registerForm = this.formBuilder.group({
      orgMarcado: [],
      regionMarcado: [],
      divisionMarcado: [],
      merchantMarcado: [],
    });

    await this.cargar();

    let orgBBDD = null;
    let regionBBDD = null;
    let divisionBBDD = null;
    let merchantBBDD = null;
    const precargaOrg = localStorage.getItem('ORGANIZATION');

    const filter: FilterAndPagination = {
      orderDirection: 'DESC',
      orderColumn: 'name',
      filterGeneric:[],
      page: 0,
      limit: 100,
      pagIndexStart: 0,
      pagIndexEnd: 10
    };

    if (precargaOrg) {
      const obj: Organization = JSON.parse(precargaOrg);
      this.selectedOrg = obj;
      console.log(this.selectedOrg);

      if (obj?.template) {
        if (this.commonService.checkJerarquia(obj.template, TypeJerarquia.REGION)) {
          filter.filterGeneric = [
            { column: 'type', value: TypeJerarquiaText.REGION, operation: 'EQUALS', type: 'STRING' },
          ];
          };
          await this.cargarRegiones(filter);
        } else if (this.commonService.checkJerarquia(obj.template, TypeJerarquia.DIVISION)) {
          filter.filterGeneric = [
            { column: 'type', value: TypeJerarquiaText.DIVISION, operation: 'EQUALS', type: 'STRING' },
          ];
          await this.cargarDivisiones(filter);
        } else {
          filter.filterGeneric = [
            { column: 'type', value: TypeJerarquiaText.MERCHANT, operation: 'EQUALS', type: 'STRING' },
          ];
          await this.cargarMerchants(filter);
        }
      

      this.listaOrg.forEach(async element => {
        if (element.domainRoot == obj.domainRoot) {
          orgBBDD = element

        };
      });

    }

    const precargaReg = localStorage.getItem('REGION');
    if (precargaReg) {
      const obj: Merchants = JSON.parse(precargaReg);
      if (obj && this.selectedOrg.template) {
        if (this.commonService.checkJerarquia(this.selectedOrg.template, TypeJerarquia.DIVISION)) {
          filter.filterGeneric = [
            { column: 'type', value: TypeJerarquiaText.DIVISION, operation: 'EQUALS', type: 'STRING' },
          ];
          await this.cargarDivisiones(filter);
        } else {
          filter.filterGeneric = [
            { column: 'type', value: TypeJerarquiaText.MERCHANT, operation: 'EQUALS', type: 'STRING' },
          ];
          await this.cargarMerchants(filter);
        }
      }

      this.listaRegion.forEach((element) => {
        if (element.domain === obj.domain) {
          regionBBDD = element;
        }
      });
    }

    const precargaDiv = localStorage.getItem('DIVISION');
    if (precargaDiv) {
      const obj: Merchants = JSON.parse(precargaDiv);
      filter.filterGeneric = [
        { column: 'type', value: TypeJerarquiaText.MERCHANT, operation: 'EQUALS', type: 'STRING' },
      ];
      await this.cargarMerchants(filter);

      this.listaDivision.forEach((element) => {
        if (element.domain === obj.domain) {
          divisionBBDD = element;
        }
      });
    }

    const precargaMec = localStorage.getItem('MERCHANT');
    if (precargaMec) {
      const obj: Merchants = JSON.parse(precargaMec);
      this.listaMerchant.forEach((element) => {
        if (element.domain === obj.domain) {
          merchantBBDD = element;
        }
      });
    }

   

    if (this.nivel) {
      if (this.nivel === 'DIVISION') {
        this.readonlyRegion = false;
        this.readonlyDivision = true;
        this.readonlyMerchant = true;
      } else if (this.nivel === 'MERCHANT') {
        this.readonlyRegion = false;
        this.readonlyDivision = false;
        this.readonlyMerchant = true;
      }
    }

    this.domainService.emitCachedDomainValue();
    this.registerForm = this.formBuilder.group({
      orgMarcado: [{ value: orgBBDD, disabled: this.readonlyOrganization }],
      regionMarcado: [{ value: regionBBDD, disabled: this.readonlyRegion }],
      divisionMarcado: [{ value: divisionBBDD, disabled: this.readonlyDivision }],
      merchantMarcado: [{ value: merchantBBDD, disabled: this.readonlyMerchant }]
    });
  }

  async cargarRegiones(filter: FilterAndPagination): Promise<void> {
    try {
      const sr = await this.organizationService.findMerchants(filter).toPromise();
      if (sr!.content && sr!.content.length > 0) {
        this.verRegion = true;
        this.listaRegion = sr!.content;
      }
    } catch (error) {
      console.error('Error fetching regions:', error);
    }
  }

  async cargarDivisiones(filter: FilterAndPagination): Promise<void> {
    try {
      const sr = await this.organizationService.findMerchants(filter).toPromise();
      if (sr!.content && sr!.content.length > 0) {
        this.verDivision = true;
        this.listaDivision = sr!.content;
      }
    } catch (error) {
      console.error('Error fetching divisions:', error);
    }
  }

  async cargarMerchants(filter: FilterAndPagination): Promise<void> {
    try {
      const sr = await this.organizationService.findMerchants(filter).toPromise();
      if (sr!.content && sr!.content.length > 0) {
        this.verMerchant = true;
        this.listaMerchant = sr!.content;
      }
    } catch (error) {
      console.error('Error fetching merchants:', error);
    }
  }

  organizationFilter(ob: MatSelectChange) {
     if (ob.value !== undefined && ob.value !== "") {
      const org: Organization = ob.value;
      this.procesarOrganizacion(org);
    } else {
      localStorage.removeItem('ORGANIZATION');
      localStorage.removeItem('REGION');
      localStorage.removeItem('DIVISION');
      localStorage.removeItem('MERCHANT');
      this.domainService.emitCachedDomainValue();
    }
  }

  async procesarOrganizacion(org: Organization): Promise<void> {
    this.selectedOrg = org;

    this.listaRegion = [];
    this.listaDivision = [];
    this.listaMerchant = [];
    this.verRegion = false;
    this.verDivision = false;
    this.verMerchant = false;

    localStorage.setItem('ORGANIZATION', JSON.stringify(org));
    localStorage.removeItem('REGION');
    localStorage.removeItem('DIVISION');
    localStorage.removeItem('MERCHANT');

    const filter: FilterAndPagination = {
      orderDirection: 'DESC',
      orderColumn: 'name',
      filterGeneric: [],
      page: 0,
      limit: 100,
      pagIndexStart: 0,
      pagIndexEnd: 10
    };

    if (org.template) {
      if (this.commonService.checkJerarquia(org.template, TypeJerarquia.REGION)) {
        await this.cargarRegiones({
          ...filter,
          filterGeneric: [
            { column: 'type', value: TypeJerarquiaText.REGION, operation: 'EQUALS', type: 'STRING' },
          ]
        });
      } else if (this.commonService.checkJerarquia(org.template, TypeJerarquia.DIVISION)) {
        await this.cargarDivisiones({
          ...filter,
          filterGeneric: [
            { column: 'type', value: TypeJerarquiaText.DIVISION, operation: 'EQUALS', type: 'STRING' },
          ]
        });
      } else {
        await this.cargarMerchants({
          ...filter,
          filterGeneric: [
            { column: 'type', value: TypeJerarquiaText.MERCHANT, operation: 'EQUALS', type: 'STRING' },
          ]
        });
      }
    }
    this.domainService.emitCachedDomainValue();
  }

  async regionFilter(ob: MatSelectChange) {
     if (ob.value !== undefined && ob.value !== "") {

      const reg: Merchants = ob.value;
      this.listaDivision = [];
      this.listaMerchant = [];
      this.verDivision = false;
      this.verMerchant = false;

      localStorage.setItem('REGION', JSON.stringify(reg));
      localStorage.removeItem('DIVISION');
      localStorage.removeItem('MERCHANT');

      const filter: FilterAndPagination = {
        orderDirection: 'DESC',
        orderColumn: 'name',
        filterGeneric: [],
        page: 0,
        limit: 100,
        pagIndexStart: 0,
        pagIndexEnd: 10
      };

      if (this.selectedOrg.template) {
        if (this.commonService.checkJerarquia(this.selectedOrg.template, TypeJerarquia.DIVISION)) {
          await this.cargarDivisiones({
            ...filter,
            filterGeneric: [
              { column: 'type', value: TypeJerarquiaText.DIVISION, operation: 'EQUALS', type: 'STRING' },
            ]
          });
        } else {
          await this.cargarMerchants({
            ...filter,
            filterGeneric: [
              { column: 'type', value: TypeJerarquiaText.MERCHANT, operation: 'EQUALS', type: 'STRING' },
            ]
          });
        }
      }
    } else {
      localStorage.removeItem('REGION');
      localStorage.removeItem('DIVISION');
      localStorage.removeItem('MERCHANT');
    }
    this.domainService.emitCachedDomainValue(); // Emit the cached domain value
  }

  async divisionFilter(ob: MatSelectChange) {
     if (ob.value !== undefined && ob.value !== "") {

      const reg: Merchants = ob.value;
      localStorage.setItem('DIVISION', JSON.stringify(reg));
      localStorage.removeItem('MERCHANT');

      const filter: FilterAndPagination = {
        orderDirection: 'DESC',
        orderColumn: 'name',
        filterGeneric: [
          { column: 'type', value: TypeJerarquiaText.MERCHANT, operation: 'EQUALS', type: 'STRING' },
        ],
        page: 0,
        limit: 100,
        pagIndexStart: 0,
        pagIndexEnd: 10
      };

      await this.cargarMerchants(filter);

    } else {
      localStorage.removeItem('DIVISION');
      localStorage.removeItem('MERCHANT');

    }
    this.domainService.emitCachedDomainValue(); 
  }

  merchantFilter(ob: MatSelectChange) {
     if (ob.value !== undefined && ob.value !== "") {
      const reg: Merchants = ob.value;
      localStorage.setItem('MERCHANT', JSON.stringify(reg));
    } else {
      localStorage.removeItem('MERCHANT');
    }
    this.domainService.emitCachedDomainValue(); 
  }

  borrarFiltro() {
    localStorage.removeItem('ORGANIZATION');
    localStorage.removeItem('REGION');
    localStorage.removeItem('DIVISION');
    localStorage.removeItem('MERCHANT');
    this.listaRegion = [];
    this.listaDivision = [];
    this.listaMerchant = [];
    this.verRegion = false;
    this.verDivision = false;
    this.verMerchant = false;
    this.selectedOrg = new Organization();
    if (this.readonlyOrganization) {
      if (this.selectedInicializacion) {
        this.procesarOrganizacion(this.selectedInicializacion);
      } else {
        this.procesarOrganizacion(this.selectedOrg);
      }
    }
    this.domainService.emitCachedDomainValue(); // Emit the cached domain value
    }
}
