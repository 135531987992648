<main id="main" class="main">


    <div class="pagetitle">
      <h1  i18n>{{"Tariffs" | translate }}</h1>  
  </div>
    
    
      
      <div class="mat-elevation-z8 tablaPersonal">
        <mat-table [dataSource]="dataSource" matSort>

            <!-- Definir las columnas -->
            <ng-container matColumnDef="partyId">
              <mat-header-cell *matHeaderCellDef class="header-table">
                <div mat-sort-header i18n>{{"Party ID" | translate }}</div>  
                <div>
                  <mat-form-field>
                    <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                    <input matInput [formControl]="partyIdFilter" placeholder="" maxlength="40">
                  </mat-form-field>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.partyId}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="countryCode">
              <mat-header-cell *matHeaderCellDef  class="header-table">
                <div mat-sort-header i18n> {{"Country code" | translate }} </div>
                <div>
                  <mat-form-field>
                    <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                    <input matInput [formControl]="countryCodeFilter" placeholder="" maxlength="40">
                  </mat-form-field>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.countryCode}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="currency">
              <mat-header-cell *matHeaderCellDef  class="header-table">
                <div mat-sort-header i18n> {{"Currency" | translate }} </div>
                <div>
                  <mat-form-field>
                    <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                    <input matInput [formControl]="currencyFilter" placeholder="" maxlength="40">
                  </mat-form-field>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.currency}}</mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="minPrice">
                <mat-header-cell *matHeaderCellDef class="header-table">
                  <div mat-sort-header i18n>{{"Min price" | translate }} </div> 
                  <div>
                    <mat-form-field>
                      <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                      <input matInput [formControl]="minPriceFilter" placeholder="" maxlength="40">
                    </mat-form-field>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <button
                  [matTooltip]="'exclVat: ' + element.minPrice.exclVat + (element.currency | currencySymbol) + ' | inclVat: ' + element.minPrice.inclVat + (element.currency | currencySymbol)"
                  aria-label="Button that displays a tooltip when focused or hovered over" mat-icon-button color="primary">
                    <i class="bi bi-coin"></i> </button>
                  </mat-cell>
              </ng-container>

              <ng-container matColumnDef="maxPrice">
                <mat-header-cell *matHeaderCellDef class="header-table">
                  <div mat-sort-header i18n>{{"Max price" | translate }}</div> 
                  <div>
                    <mat-form-field>
                      <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                      <input matInput [formControl]="maxPriceFilter" placeholder="" maxlength="40">
                    </mat-form-field>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <ng-container *ngIf="element.maxPrice; else noMaxPrice">
                    <button
                      [matTooltip]="'exclVat: ' + (element.maxPrice.exclVat ?? 'N/A') + (element.currency | currencySymbol) + ' | inclVat: ' + (element.maxPrice.inclVat ?? 'N/A') + (element.currency | currencySymbol)"
                      aria-label="Button that displays a tooltip when focused or hovered over"
                      mat-icon-button
                      color="primary">
                      <i class="bi bi-coin"></i>
                    </button>
                  </ng-container>
                  <ng-template #noMaxPrice>
                    N/A
                  </ng-template></mat-cell>
              </ng-container>

            <ng-container matColumnDef="startDateTime">
              <mat-header-cell *matHeaderCellDef class="header-table">
                <div mat-sort-header i18n>{{"Star Date" | translate }}</div>
                <div>
                  <mat-form-field>
                    <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                    <input matInput [matDatepicker]="picker" [formControl]="startDateFilter" placeholder="">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <button mat-icon-button matSuffix *ngIf="startDateFilter.value" (click)="resetStartDateFilter()">
                      <mat-icon>clear</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.startDateTime | date}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="endDateTime">
              <mat-header-cell *matHeaderCellDef class="header-table">
                <div mat-sort-header i18n>{{"End Date" | translate }}</div>
                <div>
                  <mat-form-field>
                    <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                    <input matInput [matDatepicker]="picker" [formControl]="endDateFilter" placeholder="">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <button mat-icon-button matSuffix *ngIf="endDateFilter.value" (click)="resetEndDateFilter()">
                      <mat-icon>clear</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.endDateTime | date}}</mat-cell>
            </ng-container>
            <!-- Definir el header y rows -->
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
          
          <!-- Paginador -->
          <mat-paginator [length]="totalElements"
                         [pageSize]="pageSize"
                         [pageSizeOptions]="pageSizeOptions"
                         (page)="loadTariffs($event)">
          </mat-paginator>
      </div>
      </main>
  