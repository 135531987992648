import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { FilterAndPagination } from 'src/app/models/FilterAndPagination.model';
import { Location } from 'src/app/models/location.model';
import { Page } from 'src/app/models/page.model';
import { handleError } from 'src/app/shared/util/handelError';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class LocationService {


  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }
  

  findLocations(filterAndPagination: FilterAndPagination): Observable<Page<Location>> {
    return this.http.post<Page<Location>>(`${this.apiUrl}/location`, filterAndPagination)
      .pipe(
        catchError(handleError<Page<Location>>('findLocations',  { content: [], totalPages: 0, totalElements: 0, number: 0, size: 0 }))
      );
  }


}
