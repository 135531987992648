<main id="main" class="main">



  <div class="pagetitle">
    <h1 i18n>{{"Terminal" | translate }}</h1>
    <div>
      <button mat-mini-fab (click)="onNew()"> 
       <i class="bi bi-plus-lg"></i>
      </button>
    </div>
  </div>



  <div class="mat-elevation-z8 tablaPersonal">
    <mat-table [dataSource]="dataSource" matSort>

      <!-- Definir las columnas -->
      <ng-container matColumnDef="partyId">
        <mat-header-cell *matHeaderCellDef class="header-table">
          <div mat-sort-header i18n>{{"Party ID" | translate }}</div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
              <input matInput [formControl]="partyIdFilter" maxlength="40" class="form-field">
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.partyId}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="countryCode">
        <mat-header-cell *matHeaderCellDef class="header-table">
          <div mat-sort-header i18n>{{"Country code" | translate }} </div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
              <input matInput [formControl]="countryCodeFilter" class="form-field" maxlength="40">
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.countryCode}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="model">
        <mat-header-cell *matHeaderCellDef class="header-table">
          <div mat-sort-header i18n>{{"Model" | translate }} </div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
              <input matInput [formControl]="modelFilter" placeholder="" maxlength="40">
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.model}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="language">
        <mat-header-cell *matHeaderCellDef class="header-table">
          <div mat-sort-header i18n>{{"Language" | translate }}</div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
              <input matInput [formControl]="languageFilter" placeholder="" maxlength="40">
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.language}}</mat-cell>
      </ng-container>


      <ng-container matColumnDef="preAuthorizationAmount">
        <mat-header-cell *matHeaderCellDef class="header-table">
          <div mat-sort-header i18n>{{"Preauthorization Amount" | translate }} </div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
              <input matInput [formControl]="preAuthorizationAmountFilter" placeholder="" maxlength="40">
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.preAuthorizationAmount}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="merchant">
        <mat-header-cell *matHeaderCellDef class="header-table">
          <div mat-sort-header i18n>{{"Merchant" | translate }} </div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
              <input matInput [formControl]="merchantFilter" placeholder="" maxlength="40">
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.merchant}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef class="header-table">
          <div mat-sort-header i18n>{{"Status" | translate }}</div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
              <mat-select [formControl]="statusFilter" placeholder="">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let status of statuses" [value]="status">{{ translations[status] }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="DEFAULT {{element.status}} ">
          <div>{{translations[element.status]}}</div>
        </mat-cell>
      </ng-container>



      <ng-container matColumnDef="accions">
        <mat-header-cell *matHeaderCellDef>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="button">
          <button (click)="openDialog(element)" mat-icon-button color="primary">
            <i class="bi bi-journal-text"></i> </button>

        </mat-cell>
      </ng-container>


      <!-- Definir el header y rows -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <!-- Paginador -->
    <mat-paginator [length]="totalElements" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
      (page)="loadTerminals($event)">
    </mat-paginator>
  </div>
</main>