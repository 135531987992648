import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IncidenceService } from '../../services/incident.service';
import { Incidence } from 'src/app/models/incidence.model';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-incidence-details',
  templateUrl: './incidence-details.component.html',
  styleUrls: ['./incidence-details.component.css']
})
export class IncidenceDetailsComponent implements OnInit {
  statusList: string[] = [];
  typeList: string[] = [];

  ban: boolean = false;
  incidenceForm!: FormGroup;
  translations: any;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<IncidenceDetailsComponent>,
    private incidenceService: IncidenceService, private translate: TranslateService,private utilService: UtilService, private authService: AuthService,
    private notifier: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.translate
    .get([
      "INCIDENCE"
    ])
    .subscribe(translation => {
      this.translations = {
        ...translation.INCIDENCE.STATUS,
        ...translation.INCIDENCE.TYPE
      };
    
    });

    this.loadStatuses();
    this.loadTypes();
    if (this.data?.incidence) {
      this.incidenceForm = this.fb.group({
        id: new FormControl({ value: this.data.incidence.id, disabled: true }),
        terminalId: new FormControl({ value: this.data.incidence.terminalId, disabled: true }),
        status: new FormControl({ value: this.data.incidence.status, disabled: true }),
        type: new FormControl({ value: this.data.incidence.type, disabled: true }),
        description: new FormControl({ value: this.data.incidence.description, disabled: true }),
        date: new FormControl({ value: this.data.incidence.date, disabled: true }),
        resolution: new FormControl('', Validators.required),
        comment: new FormControl('', Validators.required),
        resolutionDate: new FormControl('', Validators.required),
        user: this.authService.username
      });
    } else {
      this.ban = true;
      this.incidenceForm = this.fb.group({
        terminalId: new FormControl('' , Validators.required ),
        status: new FormControl('', Validators.required),
        type: new FormControl('', Validators.required),
        description: new FormControl('', Validators.required),
        date: new FormControl('', Validators.required),
        user: this.authService.username

      });
    }
  }

  loadTypes(): void {
    this.incidenceService.getTypesIncidents().subscribe((Type: string[]) => {
      this.typeList = Type;
    });
  }

  loadStatuses(): void {
    this.incidenceService.getStatusIncidents().subscribe((statuses: string[]) => {
      this.statusList = statuses;
    });
  }

  onFormSubmit(): void {
    if (this.incidenceForm.invalid) {
      console.log('Form is invalid');
      return;
    }

    let incident: Incidence = this.incidenceForm.getRawValue();  
    if(incident.resolutionDate) incident.resolutionDate = this.utilService.formatDateToISOString(incident.resolutionDate)
    if(incident.date) incident.date = this.utilService.formatDateToISOString(incident.date)


    this.incidenceService.saveIncidence(incident).subscribe({
      next: (_: Incidence) => {
        this.dialogRef.close();
        this.notifier.notify('success',  this.translate.instant('INCIDENT_SAVED_SUCCESS'));   
      },
      error: (err) => {
        this.notifier.notify('error', this.translate.instant('ERROR_SAVING_INCIDENT'));
      }
    });
  }


  onClose(): void {
    this.dialogRef.close();
  }
}
