<main id="main" class="main">


  <div class="pagetitle">
    <h1 i18n>{{"Invoices" | translate }}</h1>
  </div>



  <div class="mat-elevation-z8 tablaPersonal">
    <mat-table [dataSource]="dataSource" matSort>

      <!-- Definir las columnas -->
      <ng-container matColumnDef="partyId">
        <mat-header-cell *matHeaderCellDef class="header-table">
          <div mat-sort-header i18n>{{"Party ID" | translate }}</div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
              <input matInput [formControl]="partyIdFilter" placeholder="" maxlength="40">
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let client">{{client.partyId}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="countryCode">
        <mat-header-cell *matHeaderCellDef class="header-table">
          <div mat-sort-header i18n>{{"Country Code" | translate }}</div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
              <input matInput [formControl]="countryCodeFilter" placeholder="" maxlength="40">
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.countryCode}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="authMethod">
        <mat-header-cell *matHeaderCellDef class="header-table">
          <div mat-sort-header i18n>{{"Auth Method" | translate }}</div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
              <input matInput [formControl]="authMethodFilter" placeholder="" maxlength="40">
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.authMethod}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="currency">
        <mat-header-cell *matHeaderCellDef class="header-table">
          <div mat-sort-header i18n>{{"Currency" | translate }}</div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
              <input matInput [formControl]="currencyFilter" placeholder="" maxlength="40">
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.currency}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="remark">
        <mat-header-cell *matHeaderCellDef class="header-table">
          <div mat-sort-header i18n>{{"Remark" | translate }}</div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
              <input matInput [formControl]="remarkFilter" placeholder="" maxlength="40">
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.remark}}</mat-cell>
      </ng-container>


      <ng-container matColumnDef="evseId">
        <mat-header-cell *matHeaderCellDef class="header-table">
          <div mat-sort-header i18n>{{"Charging point ID" | translate }}</div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
              <input matInput [formControl]="evseIdFilter" placeholder="" maxlength="40">
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button [matTooltip]="('Charging point ID' | translate) + ': ' + element.cdrLocation.evseId"
            aria-label="Button that displays a tooltip when focused or hovered over" mat-icon-button color="primary"
            (click)="openDialogCharacteristics(element,'evse')"
            >
            <i><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-ev-station" viewBox="0 0 16 16">
                <path
                  d="M3.5 2a.5.5 0 0 0-.5.5v5a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 .5-.5v-5a.5.5 0 0 0-.5-.5zm2.131 10.46H4.14v-.893h1.403v-.505H4.14v-.855h1.49v-.54H3.485V13h2.146zm1.316.54h.794l1.106-3.333h-.733l-.74 2.615h-.031l-.747-2.615h-.764z" />
                <path
                  d="M3 0a2 2 0 0 0-2 2v13H.5a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1H11v-4a1 1 0 0 1 1 1v.5a1.5 1.5 0 0 0 3 0V4a.5.5 0 0 0-.146-.354l-.5-.5a.5.5 0 0 0-.707 0l-.5.5A.5.5 0 0 0 13 4v3c0 .71.38 1.096.636 1.357l.007.008c.253.258.357.377.357.635v3.5a.5.5 0 1 1-1 0V12a2 2 0 0 0-2-2V2a2 2 0 0 0-2-2zm7 2v13H2V2a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1" />
              </svg></i> </button>
        </mat-cell>
      </ng-container>


      <ng-container matColumnDef="connectorId">
        <mat-header-cell *matHeaderCellDef class="header-table">
          <div mat-sort-header i18n>{{"Connector ID" | translate }}</div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
              <input matInput [formControl]="connectorIdFilter" placeholder="" maxlength="40">
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button [matTooltip]="('Connector ID' | translate) + ': ' + element.cdrLocation.connectorId"
            aria-label="Button that displays a tooltip when focused or hovered over" mat-icon-button color="primary"
            (click)="openDialogCharacteristics(element,'connector')"
            >
            <i class="bi bi-plug"></i> </button></mat-cell>
      </ng-container>

      <ng-container matColumnDef="city">
        <mat-header-cell *matHeaderCellDef class="header-table">
          <div mat-sort-header i18n>{{"City" | translate }}</div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
              <input matInput [formControl]="cityFilter" placeholder="" maxlength="40">
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.cdrLocation.city}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="totalCost">
        <mat-header-cell *matHeaderCellDef class="header-table">
          <div mat-sort-header i18n>{{"Total cost" | translate }}</div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
              <input matInput [formControl]="totalCostFilter" placeholder="" maxlength="40">
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button
          [matTooltip]="'exclVat: ' + element.totalCost.exclVat + (element.currency | currencySymbol) + ' | inclVat: ' + element.totalCost.inclVat + (element.currency | currencySymbol)"
          aria-label="Button that displays a tooltip when focused or hovered over" mat-icon-button color="primary">
            <i class="bi bi-coin"></i> </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="totalEnergy">
        <mat-header-cell *matHeaderCellDef class="header-table">
          <div mat-sort-header i18n>{{"Total energy" | translate }}</div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
              <input matInput [formControl]="totalEnergyFilter" placeholder="" maxlength="40">
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.totalEnergy}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="endDateTime">
        <mat-header-cell *matHeaderCellDef class="header-table">
          <div mat-sort-header i18n>{{"End date" | translate }}</div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
              <input matInput [matDatepicker]="picker" [formControl]="endDateTimeFilter" placeholder="">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <button mat-icon-button matSuffix *ngIf="endDateTimeFilter.value" (click)="resetEndDateTimeFilter()">
                <mat-icon>clear</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.endDateTime | date}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button (click)="openDialogCharacteristics(element,'tariff')" mat-icon-button color="primary">
            <i class="bi bi-bank"></i>
            </button>
          <button (click)="openDialog(element)" mat-icon-button color="primary">
            <i class="bi bi-journal-text"></i>
          </button>

        </mat-cell>
      </ng-container>

      <!-- Definir el header y rows -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <!-- Paginador -->
    <mat-paginator [length]="totalElements" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
      (page)="loadCdrs($event)">
    </mat-paginator>
  </div>
</main>