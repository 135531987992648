import {  Injectable } from '@angular/core';
import { ChangePassword } from 'src/app/models/changePassword.model';
import { UserProfile } from 'src/app/models/users.model';
import { CommonService } from 'src/app/services/common.service';


@Injectable({
  providedIn: 'root',
})
export class UsersProfileService {
  
  constructor(
    public commonService: CommonService) {

    }

  async changePassword(_body: ChangePassword){
    // let value = await this.merchantRest.commonRestCallAuth(_body, 'user/changePassword',true,true)
    // .catch(err => {      
    //   return null
    // })
    // return value;   
  }


  async editProfile(_body: UserProfile){
    // let value = await this.merchantRest.commonRestCallAuth(_body, 'user/profile',true,true)   
    // .catch(err => {
    //   return null
    // })

    // return value;   
  }



 
}