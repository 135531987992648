<main id="main" class="main">


    <div class="pagetitle">
      <h1 i18n>{{"Connectors" | translate }}</h1>  
  </div>
    
    
      
      <div class="mat-elevation-z8 tablaPersonal">
        <mat-table [dataSource]="dataSource" matSort>

            <!-- Definir las columnas -->
            <ng-container matColumnDef="connectorId">
              <mat-header-cell *matHeaderCellDef class="header-table">
                <div mat-sort-header i18n>{{"Connector ID" | translate }}</div> 
                <div>
                  <mat-form-field>
                    <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                    <input matInput [formControl]="connectorIdFilter" placeholder="" maxlength="40">
                  </mat-form-field>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.connectorId}}</mat-cell>
            </ng-container>
           

            <ng-container matColumnDef="powerType">
              <mat-header-cell *matHeaderCellDef class="header-table">
                <div mat-sort-header i18n>{{"Power type" | translate }}</div> 
                <div>
                  <mat-form-field>
                    <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>         
                    <mat-select [formControl]="powerTypeFilter" placeholder="">
                      <mat-option value=""></mat-option>
                      <mat-option *ngFor="let powerType of powerTypes" [value]="powerType">{{ powerType }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.powerType}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="connectorFormat">
                <mat-header-cell *matHeaderCellDef class="header-table">
                  <div mat-sort-header i18n>{{"Connector format" | translate }}</div> 
                  <div>
                    <mat-form-field>
                      <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>         
                      <mat-select [formControl]="connectorFormatFilter" placeholder="">
                        <mat-option value=""></mat-option>
                        <mat-option *ngFor="let connectorFormat of connectorFormats" [value]="connectorFormat">{{  translations[connectorFormat] }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">{{  translations[element.format] ?? 'N/A'}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="standard">
                <mat-header-cell *matHeaderCellDef class="header-table">
                  <div mat-sort-header i18n>{{"Standard" | translate }}</div> 
                  <div>
                    <mat-form-field>
                      <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>         
                      <mat-select [formControl]="standardFilter" placeholder="">
                        <mat-option value=""></mat-option>
                        <mat-option *ngFor="let standard of connectorTypes" [value]="standard">{{ standard }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.standard}}</mat-cell>
              </ng-container>

            <ng-container matColumnDef="maxAmperage">
                <mat-header-cell *matHeaderCellDef class="header-table">
                  <div mat-sort-header i18n>{{"Max amperage" | translate }}</div> 
                  <div>
                    <mat-form-field>
                      <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                      <input matInput [formControl]="maxAmperageFilter" placeholder="" maxlength="40">
                    </mat-form-field>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.maxAmperage}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="maxVoltage">
                <mat-header-cell *matHeaderCellDef class="header-table">
                  <div mat-sort-header i18n>{{"Max voltage" | translate }}</div> 
                  <div>
                    <mat-form-field>
                      <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                      <input matInput [formControl]="maxVoltageFilter" placeholder="" maxlength="40">
                    </mat-form-field>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.maxVoltage  ?? 'N/A'}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="lastUpdated">
                <mat-header-cell *matHeaderCellDef class="header-table">
                  <div mat-sort-header i18n>{{"Last Updated" | translate }}</div>
                  <div>
                    <mat-form-field>
                      <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                      <input matInput [matDatepicker]="picker" [formControl]="lastUpdatedFilter" placeholder="">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                      <button mat-icon-button matSuffix *ngIf="lastUpdatedFilter.value" (click)="resetLastUpdatedFilter()">
                        <mat-icon>clear</mat-icon>
                      </button>
                    </mat-form-field>
                  </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.lastUpdated | date}}</mat-cell>
              </ng-container>
              
              <ng-container matColumnDef="accions">
                <mat-header-cell *matHeaderCellDef >
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <button (click)="openDialog(element)" mat-icon-button color="primary">
                    <i class="bi bi-journal-text"></i>
                    </button>
                
                </mat-cell>
              </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
          
          <mat-paginator [length]="totalElements"
                         [pageSize]="pageSize"
                         [pageSizeOptions]="pageSizeOptions"
                         (page)="loadConnectors($event)">
          </mat-paginator>
      </div>
      </main>
  