import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CredentialsService } from '../../services/credentials.service';
import { NotifierService } from 'angular-notifier';
import { ClientService } from '../../../client/services/client.service';
import { ManagementCpo } from 'src/app/models/managementCpo.model';
import { Client } from 'src/app/models/clients.model';
import { Page } from 'src/app/models/page.model';
import { partyIdValidator } from 'src/app/shared/util/util';
import { TranslateService } from '@ngx-translate/core';
import { FilterAndPagination } from 'src/app/models/FilterAndPagination.model';

@Component({
  selector: 'app-new-credentials',
  templateUrl: './new-credentials.component.html',
  styleUrls: ['./new-credentials.component.css']
})
export class NewCredentialsComponent implements OnInit {
  partyIdList: string[] = [];
  filteredList: string[] = []; 
  credentialForm!: FormGroup;
  ban: boolean = true;

  filterAndPagination: FilterAndPagination = {
    orderDirection: 'DESC',
    orderColumn: 'partyId',
    filterGeneric: [],
  };

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<NewCredentialsComponent>,
    private credentialsService: CredentialsService, private clientService: ClientService,
    private notifier: NotifierService,private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {

   

    this.loadPartyIds();
    if (this.data?.credential) {
      this.ban = false;

      this.credentialForm = this.fb.group({
        partyId: new FormControl({ value: this.data.credential.partyId, disabled: true }),
        token: new FormControl(this.data.credential.token),
        url: new FormControl(this.data.credential.url),
        name: new FormControl(this.data.credential.name),
        countryCode: new FormControl(this.data.credential.countryCode),
        role: new FormControl(this.data.credential.role)
      });
    } else {
      this.credentialForm = this.fb.group({
        partyId: new FormControl('', Validators.required), 
        token: new FormControl('', Validators.required),
        url: new FormControl('', Validators.required),
      });
    }

    this.credentialForm.get('partyId')!.valueChanges.subscribe(value => {
      this.filteredList = this._filter(value || '');
    });
  }

  loadPartyIds(): void {

    this.clientService.findClients(this.filterAndPagination).subscribe((clients: Page<Client>) => {
      this.partyIdList = Array.from(new Set(clients.content.map(client => client.partyId))); 
      this.filteredList = [...this.partyIdList]; 
       this.credentialForm.get('partyId')!.setValidators([Validators.required, partyIdValidator(this.partyIdList)]);
       this.credentialForm.get('partyId')!.updateValueAndValidity(); 
    });
  }

    private _filter(value: string): string[] {
      const filterValue = value.toLowerCase();
      return this.partyIdList.filter(partyId => partyId.toLowerCase().includes(filterValue));
    }

  onFormSubmit(): void {
    if (this.credentialForm.invalid) {
      console.log('Form is invalid');
      return;
    }

    let credentials: ManagementCpo = this.credentialForm.getRawValue();  


    this.credentialsService.saveCredential(credentials).subscribe({
      next: (_: ManagementCpo) => {
        this.dialogRef.close();
        this.notifier.notify('success', this.translate.instant('CREDENTIALS_SAVED_SUCCESS'));   
      },
      error: (err) => {
        this.notifier.notify('error', this.translate.instant('ERROR_SAVING_CREDENTIALS'));   
      }
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }



}