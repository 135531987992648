import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { FilterAndPagination } from 'src/app/models/FilterAndPagination.model';
import { Merchants } from 'src/app/models/merchants.model';
import { Organization } from 'src/app/models/organization.model';
import { Page } from 'src/app/models/page.model';
import { handleError } from 'src/app/shared/util/handelError';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {

  private apiUrl = `${environment.apiUrl}/organizations`;  // URL de tu API
  private a = `${environment.apiUrl}/merchant`;  // URL de tu API

  constructor(private http: HttpClient) {}

  /**
   * Find organizations with dynamic filters and pagination.
   * @param filterAndPagination Object containing filters and pagination options
   */
  findOrganizations(filterAndPagination: FilterAndPagination): Observable<Page<Organization>> {
    return this.http.post<Page<Organization>>(this.apiUrl, filterAndPagination)
      .pipe(
        catchError(handleError<Page<Organization>>('findOrganizations'))  // Manejo de errores
      );
  }

  findMerchants(filterAndPagination: FilterAndPagination): Observable<Page<Merchants>> {
    return this.http.post<Page<Merchants>>(this.a, filterAndPagination)
      .pipe(
        catchError(handleError<Page<Merchants>>('findMerchants'))  // Manejo de errores
      );
  }
}
