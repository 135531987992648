import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClientService } from '../../services/client.service';
import { NotifierService } from 'angular-notifier';
import { Client } from 'src/app/models/clients.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-client',
  templateUrl: './new-client.component.html',
  styleUrls: ['./new-client.component.css']
})
export class NewClientComponent implements OnInit {
  roles: string[] = [];
  statuses: string[] = [];
  clientForm!: FormGroup;
  translations: any;
  ban: boolean = true;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<NewClientComponent>,
    private clientService: ClientService,
    private notifier: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.loadRoles();
    this.loadStatuses();
    if (this.data?.client) {
      this.ban = false;
      this.clientForm = this.fb.group({
        partyId: new FormControl( this.data.client.partyId, [Validators.required, Validators.minLength(3), Validators.maxLength(3)]), 
        countryCode: new FormControl(this.data.client.countryCode , [Validators.required, Validators.minLength(2), Validators.maxLength(2)]),
        role: new FormControl(this.data.client.role, Validators.required),
        status: new FormControl(this.data.client.status, Validators.required)
      });
    } else {
      this.clientForm = this.fb.group({
        partyId: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(3)]), 
        countryCode: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]), 
        role: new FormControl('', Validators.required),
        status: new FormControl('', Validators.required)
      });
    }

    this.translate
      .get(["CLIENT"])
      .subscribe(translation => {
        this.translations = {
          ...translation.CLIENT.ROLE,
          ...translation.CLIENT.STATUS
        };
      });
  }


  loadRoles(): void {
    this.clientService.getRoleClients().subscribe((roles: string[]) => {
      this.roles = roles;
    });
  }

  loadStatuses(): void {
    this.clientService.getStatusClients().subscribe((statuses: string[]) => {
      this.statuses = statuses;
    });
  }

  onFormSubmit(): void {
    if (this.clientForm.invalid) {
      console.log('Form is invalid');
      return;
    }

    let client: Client = this.clientForm.getRawValue();  


    this.clientService.saveClient(client).subscribe({
      next: (_: Client) => {
        this.dialogRef.close();
        this.translate.get('CLIENT_SAVED_SUCCESS').subscribe((res: string) => {
          this.notifier.notify('success', res);
        });      },
      error: (err) => {
        this.translate.get('ERROR_SAVING_CLIENT').subscribe((res: string) => {
          this.notifier.notify('error', res);
        });      }
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }


}
