import { Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { AuthorizationService } from '../../../pages/views/authorization/services/authorization.service';
import { EventAuthorization } from 'src/app/models/eventAuthorization';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent {
  @Input() eventAuthorizationId: string = ''; 
  events: EventAuthorization[] = []; 

  constructor(private authorizationService: AuthorizationService, private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.authorizationService.findEventAuthorizations(this.eventAuthorizationId)
      .subscribe({
        next: (data: EventAuthorization[]) => {
          this.events = data; 
        },
        error: (error) => {
          console.error('Error fetching events:', error);
        },
        complete: () => {
          console.log('Subscription completed');
        }
      });
  }
  getGradientStyle(): string {
    const colors = this.events.map(event => {
      switch (event.status) {
        case 'ERROR':
          return 'red';
        case 'WARNING':
          return '#ddd';
        case 'INFO':
          return '#2a95c3'; // Azul para INFO
        default:
          return '#ddd'; // Color por defecto
      }
    });

    const segmentSize = 100 / colors.length;

    // Crear un fondo con colores segmentados
    const gradientSegments = colors
      .map((color, index) => `${color} ${index * segmentSize}%, ${color} ${(index + 1) * segmentSize}%`)
      .join(', ');
  
    // Devolver el gradiente con segmentos puros
    return `linear-gradient(to bottom, ${gradientSegments})`;
  }
}
