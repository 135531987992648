import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { UtilService } from './util.service';

@Injectable({
    providedIn: 'root',
  })
  export class DomainService {
    private valueChangedSource = new Subject<string>();
    valueChanged$ = this.valueChangedSource.asObservable();

    constructor(
        public utilService: UtilService,
      ) {}  



    emitValueChanged(newValue: string) {
        console.log(newValue);
      this.valueChangedSource.next(newValue); // Emit the new value
    }
  
 
    // Method to emit the domain value from cache
    emitCachedDomainValue() {
      const cachedValue = this.utilService.cargarCacheDomain();
      this.emitValueChanged(cachedValue ?? "");
    }
  }