import { AfterViewInit, Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ManagementCpo } from 'src/app/models/managementCpo.model';
import { Page } from 'src/app/models/page.model';
import { environment } from 'src/environments/environment';
import { CredentialsService } from '../../services/credentials.service';
import { ClientService } from '../../../client/services/client.service';
import { NewCredentialsComponent } from '../new-credentials/new-credentials.component';
import { MatDialog } from '@angular/material/dialog';
import { FilterAndPagination } from 'src/app/models/FilterAndPagination.model';

@Component({
  selector: 'app-cpo',
  templateUrl: './cpo.component.html',
  styleUrls: ['./cpo.component.css']
})
export class CpoComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['partyId', 'countryCode', 'name', 'role', 'token', 'accions'];
  dataSource = new MatTableDataSource<ManagementCpo>();
  roles: string[] = [];
  totalElements: number = 0;
  pageSize: number = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;

  partyIdFilter = new FormControl();
  countryCodeFilter = new FormControl();
  roleFilter = new FormControl();
  nameFilter = new FormControl();

  filterAndPagination: FilterAndPagination = {
    orderDirection: 'DESC',
    orderColumn: 'partyId',
    filterGeneric: [], 
    page: 0,
    limit: environment.defaultPaginacion,
    pagIndexStart: 0,
    pagIndexEnd: environment.defaultPaginacion
  };

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  constructor(
    private credentialsService: CredentialsService,
    private clientService: ClientService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef // Para detectar cambios si es necesario
  ) {}

  ngOnInit(): void {
    this.loadCredentials();
    this.loadRoles();

    // Suscribirse a cambios en los filtros
    this.partyIdFilter.valueChanges.subscribe(() => this.applyFilters());
    this.countryCodeFilter.valueChanges.subscribe(() => this.applyFilters());
    this.roleFilter.valueChanges.subscribe(() => this.applyFilters());
    this.nameFilter.valueChanges.subscribe(() => this.applyFilters());
  }

  ngAfterViewInit(): void {
    // Usar ChangeDetectorRef para asegurar que paginator y sort están inicializados
    this.cdr.detectChanges();

    // Asignar paginador y sorter a la dataSource
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // Cambios de ordenación
    this.sort.sortChange.subscribe((sortState: Sort) => {
      this.filterAndPagination.orderColumn = sortState.active;
      this.filterAndPagination.orderDirection = sortState.direction ? sortState.direction.toUpperCase() : 'ASC';
      this.loadCredentials();
    });
  }

  applyFilters(): void {
    const filters = [];

    if (this.partyIdFilter.value) {
      filters.push({ column: 'roles.partyId', value: this.partyIdFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.countryCodeFilter.value) {
      filters.push({ column: 'roles.countryCode', value: this.countryCodeFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.roleFilter.value) {
      filters.push({ column: 'roles.role', value: this.roleFilter.value, operation: 'EQUALS', type: 'STRING' });
    }
    if (this.nameFilter.value) {
      filters.push({ column: 'roles.businessDetails.name', value: this.nameFilter.value, operation: 'LIKE', type: 'STRING' });
    }

    this.filterAndPagination.filterGeneric = filters; 
    this.loadCredentials();
  }

  loadCredentials(event?: PageEvent): void {
    this.filterAndPagination.page = event ? event.pageIndex : 0;
    this.filterAndPagination.limit = event ? event.pageSize : this.pageSize;
    this.filterAndPagination.pagIndexStart = this.filterAndPagination.page * this.filterAndPagination.limit;
    this.filterAndPagination.pagIndexEnd = this.filterAndPagination.pagIndexStart + this.filterAndPagination.limit;

    this.credentialsService.findCredentials(this.filterAndPagination).subscribe((page: Page<ManagementCpo>) => {
      this.dataSource.data = page.content;
      this.totalElements = page.totalElements;
      this.pageSize = page.size;

      // Verificar que paginator no sea undefined
      if (this.paginator) {
        this.paginator.length = this.totalElements;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
      }
    });
  }

  loadRoles(): void {
    this.clientService.getRoleClients().subscribe((roles: string[]) => {
      this.roles = roles;
    });
  }

  onNew(): void {
    const dialogRef = this.dialog.open(NewCredentialsComponent, {
      width: '70%', height: '80%', panelClass: 'custom-modalbox',
    });

    dialogRef.afterClosed().subscribe(() => {
      this.loadCredentials();
    });
  }

  openDialog(credential: ManagementCpo): void {
    const dialogRef = this.dialog.open(NewCredentialsComponent, {
      width: '70%', height: '80%', panelClass: 'custom-modalbox',
      data: { credential: credential }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.loadCredentials();
    });
  }
}
