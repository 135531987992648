import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, of, tap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthRestUrl } from '../../shared/util/constants';
import { AuthService } from '../services/auth.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  
  constructor(private router: Router, private authService: AuthService) {}
  
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    //return next.handle(request);

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.authService.logoutAndClear();
          this.router.navigateByUrl('/welcome');
        }
      
        
        console.debug(JSON.stringify(err));
       // return throwError(()=> new Error($localize `Unauthorized access`) );
       return throwError(()=> new Error(JSON.stringify(err)) );
    }));
  }
}
