import { Observable, of, throwError } from "rxjs";

export function handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message}`); 
      return throwError(() => new Error(`${operation} failed: ${error.message}`));
    };
  }

  
export function handleErroForList<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }