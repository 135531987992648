import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Connector } from 'src/app/models/connector.model';

@Component({
  selector: 'app-connector-tariffs',
  templateUrl: './connector-tariffs.component.html',
  styleUrls: ['./connector-tariffs.component.css']
})
export class ConnectorTariffsComponent {

  constructor(
    public dialogRef: MatDialogRef<ConnectorTariffsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Connector
  ) {
  }

  
  onClose(): void {
    this.dialogRef.close();
  }
}
