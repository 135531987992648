<section class="section">
    <div class="pagetitle pagetitle-modal">
        <h1 i18n>{{"Details Charging point - EVSE" | translate }} {{data.evseId}}</h1>
    </div>
    <mat-tab-group>
        <mat-tab label="connector list">
            <div class="row ajuste-contenedor-tab">
                <app-connector [data]="this.connectorsIds"></app-connector>
            </div>
        </mat-tab>
        <mat-tab label="tariff list">
            <div class="row ajuste-contenedor-tab">
                <app-tariff [data]="this.tariffsIds"></app-tariff> 
            </div>
        </mat-tab>
    </mat-tab-group>
</section>