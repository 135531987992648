import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EVSE } from 'src/app/models/evse.model';
import { getAllConnectorIds, getAllTariffIds } from 'src/app/shared/util/util';

@Component({
  selector: 'app-evse-connector-tariff',
  templateUrl: './evse-connector-tariff.component.html',
  styleUrls: ['./evse-connector-tariff.component.css']
})
export class EvseConnectorComponent {

  constructor(
    public dialogRef: MatDialogRef<EvseConnectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EVSE
  ) {
  }

  connectorsIds: string[] = [];
  tariffsIds: string[] = [];

  ngOnInit(): void {
      this.tariffsIds = getAllTariffIds(this.data);
      this.connectorsIds= getAllConnectorIds(this.data);
  }
  onClose(): void {
    this.dialogRef.close();
  }
}