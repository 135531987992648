
<section class="section">
    <div class="pagetitle pagetitle-modal">
        <h1 i18n>{{"Incidence" | translate }}</h1>  
    </div>
<mat-tab-group [formGroup]="incidenceForm">
    <mat-tab [label]="'New incidence' | translate" label="" *ngIf="ban">
        <div class="row ajuste-contenedor-tab">

            <div class="col-lg-6"> 
               <div class="card cardCustos">
                   <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Terminal ID" | translate }}</h5>
                        <input formControlName="terminalId" matInput type="text">
                        <mat-error *ngIf="incidenceForm.get('terminalId')?.hasError('required')">
                          {{"ID is required." | translate }} 
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="card cardCustos">
                  <mat-form-field ngDefaultControl>
                    <h5 i18n>{{"Status" | translate }}</h5>
                    <mat-select formControlName="status">
                      <mat-option *ngFor="let status of statusList" [value]="status">
                        {{ translations[status] }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="incidenceForm.get('status')?.hasError('required')">
                      {{"Status is required." | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              
 
              <div class="col-lg-6">
                <div class="card cardCustos">
                  <mat-form-field ngDefaultControl>
                    <h5 i18n>{{"Type" | translate }} </h5>
                    <mat-select formControlName="type">
                      <mat-option *ngFor="let type of typeList" [value]="type">
                        {{ translations[type] }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="incidenceForm.get('type')?.hasError('required')">
                     {{"Type is required." | translate }} 
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              
            
             <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Description" | translate }} </h5>
                         <input formControlName="description" matInput type="text">
                         <mat-error *ngIf="incidenceForm.get('description')?.hasError('required')">
                          {{"Description is required." | translate }} 
                        </mat-error>
                     </mat-form-field>
                 </div>
             </div>

             <div class="col-lg-12">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Date" | translate }} </h5>
                         <input formControlName="date" matInput type="date">
                         <mat-error *ngIf="incidenceForm.get('date')?.hasError('required')">
                          {{"Date is required." | translate }}
                        </mat-error>
                     </mat-form-field>
                 </div>
             </div> 
           
        </div>

    </mat-tab>
    <mat-tab [label]="'Finish' | translate" *ngIf="!ban">
        <div class="row ajuste-contenedor-tab">
           
             <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Resolution" | translate }} </h5>
                         <input formControlName="resolution" matInput type="text">
                         <mat-error *ngIf="incidenceForm.get('resolution')?.hasError('required')">
                          {{"Resolution is required." | translate }}
                        </mat-error>
                     </mat-form-field>
                 </div>
             </div> 

             <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Comment" | translate }}</h5>
                         <input formControlName="comment" matInput type="text">
                         <mat-error *ngIf="incidenceForm.get('comment')?.hasError('required')">
                           {{"Comment is required." | translate }}
                        </mat-error>
                     </mat-form-field>
                 </div>
             </div> 

             <div class="col-lg-12">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Resolution date" | translate }}</h5>
                         <input formControlName="resolutionDate" matInput type="date">
                         <mat-error *ngIf="incidenceForm.get('resolutionDate')?.hasError('required')">
                          {{"Resolution date is required." | translate }}
                        </mat-error>
                     </mat-form-field>
                 </div>
             </div> 
           
        </div>

    </mat-tab>

  </mat-tab-group>

    <div style=" margin: 2em">
            <button  class="btn btn-aceptar" (click)="onFormSubmit()" i18n>{{"Submit" | translate }}</button> 
            <button  class="btn btn-cancelar" (click)="onClose()" i18n>{{"Cancel" | translate }}</button>
    </div>

</section>