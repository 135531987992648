// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { BuildFlags, LogLevel } from "src/app/shared/util/cm-types";
import { ENTORNO } from "src/app/shared/util/constants";


export const environment = {
  production: false,
  debugInfo: false,
  paginacion: [5 , 10, 25, 50, 100],
  defaultPaginacion: 10,
  apiUrl: '/api/emsp'

};

/**
 * Configuracion general de la aplicacion
 */
 export const AppConfig = {
  RunSymbols: BuildFlags.DEBUG | BuildFlags.DEV,
  LogLevel: LogLevel.TRACE,
  // Longitud maxima de texto volcado al log en una linea
  LogMsgMaxLength: 1024,
}


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
//export const SERVER_URL_MERCHANT = 'https://dev-merchant-portal.custosmobile.com/';
//export const SERVER_URL_AUTH = 'https://devauth.custosmobile.com/';//'http://10.20.0.11:9000';

export const SERVER_URL_AUTH = 'https://authd.custosmobile.com/';
export const SERVER_URL_EMSP = '/api/emsp/';

export const REFRESH_DASHBOARD = 60000;

export const VIWER_ENTORNO = ENTORNO.GETNET;