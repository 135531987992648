import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, tap } from 'rxjs';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  //private router: Router, 
  constructor(
    
    private authService: AuthService,
    private router: Router,
    ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.hasValidAccessToken()
            &&  this.authService.hasValidIdToken()) {
    
      let check = route.data;
      if ((check != undefined) && (check["authorg"] != undefined) ) {
        var authorg : string = check["authorg"] as string;
        var authkey : string = check["authkey"] as string;
        const tk = this.authService.getUserAccessToken();
        const accessEntries = tk!.auth!.access as Array<{ permissions: { [key: string]: { [key: string]: string } } }>;
        const permission = accessEntries.find((entry: { permissions: { [key: string]: { [key: string]: string } } }) => 
            entry.permissions[authorg] && entry.permissions[authorg][authkey]
        );
        return permission != undefined && permission.permissions[authorg][authkey].length > 0;
    }
      return true;
    } else {
      console.log("Aquí es el error (auth guard)")
      this.authService.logoutAndClear();
      return false;
    }
  }
}
