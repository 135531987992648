<section class="section">
    <div class="pagetitle pagetitle-modal">
      <h1 i18n>{{"Details Invoice" | translate }} - {{this.data.cdr.cdrId}}</h1>
    </div>
    <mat-tab-group [selectedIndex]="selectedTabIndex">
      <mat-tab [label]="'Charging point list' | translate">
        <div class="row ajuste-contenedor-tab">
          <app-evse [data]="evsesIds"></app-evse>
        </div>
      </mat-tab>
      <mat-tab [label]="'Connector list' | translate">
        <div class="row ajuste-contenedor-tab">
          <app-connector [data]="connectorsIds"></app-connector>
        </div>
      </mat-tab>
      <mat-tab [label]="'Tariff list' | translate">
        <div class="row ajuste-contenedor-tab">
          <app-tariff [data]="tariffsIds"></app-tariff>
        </div>
      </mat-tab>
    </mat-tab-group>
  </section>
  