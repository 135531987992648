import { AfterViewInit, Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Authorization } from 'src/app/models/authorization.model';
import { environment } from 'src/environments/environment';
import { Page } from 'src/app/models/page.model';
import { MatDialog } from '@angular/material/dialog';
import { UtilService } from 'src/app/services/util.service';
import { AuthorizationService } from '../../services/authorization.service';
import { AuthorizationDetailsComponent } from '../authorization-details/authorization-details.component';
import { FilterAndPagination } from 'src/app/models/FilterAndPagination.model';
import { DomainService } from 'src/app/services/domain.service';

@Component({
  selector: 'app-authorization',
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.css']
})
export class AuthorizationComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['partyId', 'countryCode', 'evseUid', 'connectorId', 'status', 'authorizationReference', 'lastUpdated', 'accions'];
  
  dataSource = new MatTableDataSource<Authorization>();
  totalElements: number = 0;
  pageSize: number = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;
  domainFilter: string = "";

  partyIdFilter = new FormControl();
  countryCodeFilter = new FormControl();
  evseUidFilter = new FormControl();
  connectorIdFilter = new FormControl();
  authorizationReferenceFilter = new FormControl();
  statusFilter = new FormControl();
  lastUpdatedFilter = new FormControl();

  filterAndPagination: FilterAndPagination = {
    orderDirection: 'DESC',
    orderColumn: 'partyId', 
    filterGeneric: [],
    page: 0,
    limit: environment.defaultPaginacion,
    pagIndexStart: 0,
    pagIndexEnd: environment.defaultPaginacion
  };

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  constructor(
    private authorizationService: AuthorizationService,
    public dialog: MatDialog,
    private utilService: UtilService,
    private domainService: DomainService,
    private cdr: ChangeDetectorRef 
  ) {}

  ngOnInit(): void {
    this.loadAuthorizations();

    // Subscribe to changes in filters
    this.domainService.valueChanged$.subscribe((newValue) => {
      this.domainFilter = newValue; 
      this.applyFilters();
    });
    this.partyIdFilter.valueChanges.subscribe(() => this.applyFilters());
    this.countryCodeFilter.valueChanges.subscribe(() => this.applyFilters());
    this.evseUidFilter.valueChanges.subscribe(() => this.applyFilters());
    this.connectorIdFilter.valueChanges.subscribe(() => this.applyFilters());
    this.authorizationReferenceFilter.valueChanges.subscribe(() => this.applyFilters());
    this.statusFilter.valueChanges.subscribe(() => this.applyFilters());
    this.lastUpdatedFilter.valueChanges.subscribe(() => this.applyFilters());
  }

  ngAfterViewInit(): void {
    // Forzar la detección de cambios para asegurar que paginator y sort están listos
    this.cdr.detectChanges();

    // Asignar paginador y sorter a la dataSource
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // Sorting changes
    this.sort.sortChange.subscribe((sortState: Sort) => {
      this.filterAndPagination.orderColumn = sortState.active;
      this.filterAndPagination.orderDirection = sortState.direction ? sortState.direction.toUpperCase() : 'ASC';
      this.loadAuthorizations();
    });
  }

  applyFilters(): void {
    const filters = [];

    if (this.partyIdFilter.value) {
      filters.push({ column: 'partyId', value: this.partyIdFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.countryCodeFilter.value) {
      filters.push({ column: 'countryCode', value: this.countryCodeFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.evseUidFilter.value) {
      filters.push({ column: 'evseUid', value: this.evseUidFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.connectorIdFilter.value) {
      filters.push({ column: 'connectorId', value: this.connectorIdFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.authorizationReferenceFilter.value) {
      filters.push({ column: 'authorizationReference', value: this.authorizationReferenceFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.statusFilter.value) {
      filters.push({ column: 'status', value: this.statusFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.lastUpdatedFilter.value) {
      filters.push({ column: 'lastUpdated', value: this.utilService.formatDateToISOString(this.lastUpdatedFilter.value), operation: 'GREATER_THAN_OR_EQUAL', type: 'DATE' });
    }
    if (this.domainFilter) {
      filters.push({ column: 'domain', value: this.domainFilter, operation: 'LIKE', type: 'STRING' });
    }
    this.filterAndPagination.filterGeneric = filters;
    this.loadAuthorizations();
  }

  loadAuthorizations(event?: PageEvent): void {
    this.filterAndPagination.page = event ? event.pageIndex : 0;
    this.filterAndPagination.limit = event ? event.pageSize : this.pageSize;
    this.filterAndPagination.pagIndexStart = this.filterAndPagination.page * this.filterAndPagination.limit;
    this.filterAndPagination.pagIndexEnd = this.filterAndPagination.pagIndexStart + this.filterAndPagination.limit;

    this.authorizationService.findAuthorizations(this.filterAndPagination).subscribe((page: Page<Authorization>) => {
      this.dataSource.data = page.content;
      this.totalElements = page.totalElements;
      this.pageSize = page.size;

      if (this.paginator) {
        this.paginator.length = this.totalElements;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
      }
    });
  }

  resetLastUpdatedFilter(): void {
    this.lastUpdatedFilter.setValue(null);
  }

  openDialog(authorization: Authorization): void {
    const dialogRef = this.dialog.open(AuthorizationDetailsComponent, {
      width: '70%',
      height: '80%',
      panelClass: 'custom-modalbox',
      data: { authorization: authorization }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.loadAuthorizations();
    });
  }
}
