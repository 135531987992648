import { Injectable } from '@angular/core';
import { Organization, OrganizationTemplate } from '../models/organization.model';
import { TypeJerarquia } from '../shared/util/constants';
import { RequestFilter } from '../models/requestFilter.model';
import { Merchants } from '../models/merchants.model';
import { VIWER_ENTORNO } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { OrganizationsService } from '../pages/views/organizations/services/organizations.service';
import { FilterAndPagination } from '../models/FilterAndPagination.model';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  
  entorno = VIWER_ENTORNO;
  private registeredModals: MatDialogRef<any>[] = [];

  constructor(
      private translateService: TranslateService,
      private organizationService: OrganizationsService) {}


  public checkJerarquia(templace:OrganizationTemplate | undefined, nivel:TypeJerarquia): boolean  {
    if (templace && nivel) {     
        let permiso = false;

        if(nivel == TypeJerarquia.REGION && templace.hasRegions) {     
            permiso = templace.hasRegions;
        }else if(nivel == TypeJerarquia.DIVISION && templace.hasDelegations) {     
            permiso = templace.hasDelegations;
        }else if(nivel == TypeJerarquia.SUBSIDIARY && templace.hasSubsidiary) {     
            permiso = templace.hasSubsidiary;
        }     
        return permiso;      
    } 
      
    return false;

  }



 






public ObtenerJerarquia(dominio:string, domainDetail:string):Map<string,string>{
  let mapa=new Map();
  if(dominio && domainDetail){
        let domainList = dominio.split(".")
        let domainDetailList = domainDetail.split(".")
        let domainDetailListFinal:string[]=[]

        domainDetailList.forEach((val)=>{
          if(val!="*"){
            domainDetailListFinal.push(val)
          }
        });

      
        let i=0;
        if(domainDetailListFinal.length>0){
          while(i<domainList.length){

            let dominioEntero=""
            let j=0
            while(j<=i){        
              if(j>0){
                dominioEntero=dominioEntero.concat(".")
              }
              dominioEntero=dominioEntero.concat(domainList[j])
              j++
            }
            mapa.set(domainDetailListFinal[i],dominioEntero);
            i++
          }
        }
  }
  return mapa
}





cargarCacheDomainOnlyOrg() {
      
  let precargaOrg = localStorage.getItem('ORGANIZATION'); 
  
  let value:string | undefined
 if(precargaOrg && precargaOrg!="undefined"){
    let obj:Organization
    obj= JSON.parse(precargaOrg); 
    if(obj){
      value=obj.domainRoot
    }
  }
 return value;
}

cargarCacheDomain() {
      
  let precargaOrg = localStorage.getItem('ORGANIZATION'); 
  let precargaReg = localStorage.getItem('REGION');  
  let precargaDiv= localStorage.getItem('DIVISION');
  let precargaMec = localStorage.getItem('MERCHANT');
  
  let value:string | undefined
  if(precargaMec && precargaMec!="undefined" ){
    let obj:Merchants
    obj= JSON.parse(precargaMec); 
    if(obj){
      value=obj.domain
    }
  }else if(precargaDiv && precargaDiv!="undefined"){
    let obj:Merchants
    obj= JSON.parse(precargaDiv); 
    if(obj){
      value=obj.domain
    }
  }else if(precargaReg && precargaReg!="undefined"){
    let obj:Merchants
    obj= JSON.parse(precargaReg); 
    if(obj){
      value=obj.domain
    }
  }else if(precargaOrg && precargaOrg!="undefined"){
    let obj:Organization
    obj= JSON.parse(precargaOrg); 
    if(obj){
      value=obj.domainRoot
    }
  }
 return value;
}



obtenerStructure(isPulsadoRegion:boolean,isPulsadoDivision:boolean,isPulsadoMerchant:boolean,isPulsadoSubsidiary:boolean) {
    let structure="O";
    if(isPulsadoRegion){
      structure = structure.concat(".R");
  }else{
    structure = structure.concat(".*");
  }
  if(isPulsadoDivision){
    structure = structure.concat(".D");
  }else{
    structure = structure.concat(".*");
  }
  if(isPulsadoMerchant){
    structure = structure.concat(".M");
  }else{
    structure = structure.concat(".*");
  }
  if(isPulsadoSubsidiary){
    structure = structure.concat(".S");
  }else{
    structure = structure.concat(".*");
  }

 return structure;
}




async cargarCacheOrganization() {
      
  let precargaOrg = localStorage.getItem('ORGANIZATION'); 
  if (!precargaOrg) {
    //Check the user has more than 1 organization selectable:
    const filter: FilterAndPagination = {
      orderDirection: 'DESC',
      orderColumn: 'name',
      filterGeneric: [],
      page: 0,
      limit: 100,
      pagIndexStart: 0,
      pagIndexEnd: 10
    };
    // let srOrg = await this.orgService.find(rfOrg,true);
    const srOrg = await this.organizationService.findOrganizations(filter).toPromise();

    if (srOrg?.content && srOrg.content.length==1) {      
        // Si solo hay 1 ponemos por defecto valor
        precargaOrg = srOrg.content[0].name;        
        localStorage.setItem('ORGANIZATION', JSON.stringify(srOrg.content[0]));      
    } 

  }

 return precargaOrg;
}

public translate(text : string) : string {
  if(text && ''!==text){
    return this.translateService.instant(text);
  }
  return text;
}
public registerDialogModal(dialog: MatDialogRef<any>) : void {
  this.registeredModals.push(dialog);
}

public closeAllModals() {
  this.registeredModals.forEach(dialog => {
    dialog.close();
  });
  this.registeredModals = [];
}

formatDateToISOString(date: Date | string): string {
  const isoString = new Date(date).toISOString().split('T')[0] + 'T00:00:00';
  return isoString;
}

}