<main id="main" class="main">


    <div class="pagetitle">
        <h1 i18n>{{"Locations" | translate }}</h1>
    </div>


    <div class="map-container">
        <app-map *ngIf="coordinates.length > 0"  [coordinates]="coordinates"></app-map>
    </div>



</main>