import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, shareReplay } from 'rxjs';
import { Page } from 'src/app/models/page.model';
import { EVSE } from 'src/app/models/evse.model';
import { handleError } from 'src/app/shared/util/handelError';
import { environment } from 'src/environments/environment';
import { FilterAndPagination } from 'src/app/models/FilterAndPagination.model';


@Injectable({
  providedIn: 'root',
})
export class EvseService {

  private statusEvses$!: Observable<string[]>;

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
    this.initialize();
  }
  

  findEvses(filterAndPagination: FilterAndPagination): Observable<Page<EVSE>> {
    return this.http.post<Page<EVSE>>(`${this.apiUrl}/evse`, filterAndPagination )
      .pipe(
        catchError(handleError<Page<EVSE>>('findEvses', { content: [], totalPages: 0, totalElements: 0, number: 0, size: 0 }))
      );
  }


  private initialize(): void {
    this.statusEvses$ = this.http.get<string[]>(`${this.apiUrl}/evse/status`)
      .pipe(
        catchError(handleError<string[]>('fetching evse status', [])),
        shareReplay(1)
      );

   
  }

  getStatusEvses(): Observable<string[]> {
    return this.statusEvses$;
  }
}
