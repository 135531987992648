import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, shareReplay } from 'rxjs';
import { FilterAndPagination } from 'src/app/models/FilterAndPagination.model';
import { Page } from 'src/app/models/page.model';
import { Terminal } from 'src/app/models/terminal.model';
import { handleError } from 'src/app/shared/util/handelError';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class TerminalService {

  private statusTerminal$!: Observable<string[]>;
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
    this.initialize();
  }

  findTerminals(filterAndPagination: FilterAndPagination): Observable<Page<Terminal>> {

    return this.http.post<Page<Terminal>>(`${this.apiUrl}/terminal`, filterAndPagination)
      .pipe(
        catchError(handleError<Page<Terminal>>('findterminals', { content: [], totalPages: 0, totalElements: 0, number: 0, size: 0 }))
      );
  }

  preregistrationTerminal(terminaltRequest: Terminal | {}): Observable<Terminal> {
    return this.http.post<Terminal>(`${this.apiUrl}/terminal/preregistration`, terminaltRequest)
      .pipe(
        catchError(handleError<Terminal>('preregistrationTerminal'))
      );
  }



  private initialize(): void {

    this.statusTerminal$ = this.http.get<string[]>(`${this.apiUrl}/terminal/status`)
      .pipe(
        catchError(handleError<string[]>('fetching terminal status', [])),
        shareReplay(1)
      );

  }

  getStatusesTerminals(): Observable<string[]> {
    return this.statusTerminal$;
  }
}
