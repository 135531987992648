
<section class="section">
    <div class="pagetitle pagetitle-modal">
        <h1 i18n>{{"Credentials" | translate }}</h1>  
    </div>
<mat-tab-group [formGroup]="credentialForm">
    <mat-tab [label]="ban ? ('New credential' | translate) : ('Edit credential' | translate)" label="" >
      <div class="row ajuste-contenedor-tab">
        <div class="col-lg-6">
          <div class="card cardCustos">
            <mat-form-field>
              <h5 i18n>{{ "party ID" | translate }}</h5>
              <input formControlName="partyId" matInput type="text" [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let partyId of filteredList | slice:0:3" [value]="partyId">
                  {{partyId}}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="credentialForm.get('partyId')?.hasError('required')">
                {{"Party ID is required." | translate }}
              </mat-error>
              <mat-error *ngIf="credentialForm.get('partyId')?.hasError('invalidPartyId')">
                {{"Selected Party ID is not valid." | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

             <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Token" | translate }} </h5>
                         <input formControlName="token" matInput type="text">
                         <mat-error *ngIf="credentialForm.get('token')?.hasError('required')">
                          {{"Token is required." | translate }}
                        </mat-error>
                     </mat-form-field>
                 </div>
             </div> 
           

             <div class="col-lg-12">
              <div class="card cardCustos">
                  <mat-form-field ngDefaultControl>
                       <h5 i18n>{{"Url" | translate }} </h5>
                       <input formControlName="url" matInput type="text">
                       <mat-error *ngIf="credentialForm.get('url')?.hasError('required')">
                        {{"Url is required." | translate }} 
                      </mat-error>
                   </mat-form-field>
               </div>
           </div>
        </div>

    </mat-tab>
  </mat-tab-group>

    <div style=" margin: 2em">
            <button  class="btn btn-aceptar" (click)="onFormSubmit()" i18n>{{"Submit" | translate }}</button> 
            <button  class="btn btn-cancelar" (click)="onClose()" i18n>{{"Cancel" | translate }}</button>
    </div>

</section>