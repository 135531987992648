
// Funcion simple para poder establecer un timeout a una Promise.

import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { EVSE } from "src/app/models/evse.model";
import { Tariff } from "src/app/models/tariffs.model";
import { LocationDefinition, Terminal } from "src/app/models/terminal.model";

// Ejemplo de uso: let doIt = timeoutPromise(5000, doSomething())
export function promiseTimeout(ms: number, promise: Promise<any>) {
    let id:any
    // Create a promise that rejects in <ms> milliseconds
    let timeout = new Promise((resolve, reject) => {
        id = setTimeout(() => {
            clearTimeout(id);
            reject('Timed out in ' + ms + 'ms.')
        }, ms)
    })

    // Returns a race between our timeout and the passed in promise
    return Promise.race([
        promise,
        timeout
    ]).then((result) => {
        clearTimeout(id)
        // We also need to pass the result back
        return result
    })
}
export function getEvseUids(terminal: Terminal): string[] {
  return terminal.services.reduce((accumulator: string[], service) => {
    if (Array.isArray(service.evseUids)) {
      return accumulator.concat(service.evseUids);
    }
    return accumulator;
  }, []);
}
export function  getAllEvseUids(services: LocationDefinition[]): string[] {
    return services.reduce((accumulator, service) => {
      if (Array.isArray(service.evseUids)) {
        return accumulator.concat(service.evseUids);
      }
      return accumulator;
    }, [] as string[]);
  }
  export function toSingleElementArray(id: string): string[] {
    return [id];
  }
  
  export function getAllConnectorIds(evse: EVSE): string[] {
    return evse.connectors.map((connector) => connector.connectorId);
  }
  export function getTariffIds(tariffs: Tariff[]): string[] {
    return tariffs.map(tariff => tariff.tariffId);
  }
  export function getAllTariffIds(evse: EVSE): string[] {
    return evse.connectors.reduce((accumulator: string[], connector) => {
      if (connector.tariffIds) {
        return accumulator.concat(connector.tariffIds);
      }
      return accumulator;
    }, []);
  }

export function partyIdValidator(validIds: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null; 
      }
      const isValid = validIds.includes(control.value);
      return isValid ? null : { invalidPartyId: true };
    };
}
export function base64DecToArr(str: string): Uint8Array {
    return Uint8Array.from(atob(str), c => c.charCodeAt(0))
}

export class ByteUtil {
    private static readonly decoder = new TextDecoder("utf-8")
    private static readonly encoder = new TextEncoder()

    static bytesToStr(buf: BufferSource) {
        return this.decoder.decode(buf)
    }

    static strToBytes(str: string) {
        return this.encoder.encode(str);
    }

    /* Little Endian es el protocolo por defecto con el Touchless, pero en algunos sitios como la actualización
    de firmware se hace siguiendo el protocolo XMODEM-1k que usa Big Endian. Por eso permitimos por parametro la configuracion
    */
    static toBytesInt32(num: number, littleEndian = true) {
        let arr = new ArrayBuffer(4); // an Int32 takes 4 bytes
        let view = new DataView(arr);
        view.setUint32(0, num, littleEndian); // byteOffset = 0; litteEndian = true
        return new Uint8Array(arr);
    }

    static toBytesInt16(num: number, littleEndian = true) {
        let arr = new ArrayBuffer(2); // an Int16 takes 2 bytes
        let view = new DataView(arr);
        view.setUint16(0, num, littleEndian); // byteOffset = 0; litteEndian = true
        return new Uint8Array(arr);
    }

    static toBytesInt8(num: number) {
        let arr = new ArrayBuffer(1); // an Int16 takes 1 byte
        let view = new DataView(arr);
        view.setUint8(0, num); // byteOffset = 0
        return new Uint8Array(arr);
    }

    static bytesToHex(buffer: Uint8Array): string { // buffer is an ArrayBuffer
        return Array.prototype.map.call(buffer, x => ('00' + x.toString(16)).toUpperCase().slice(-2)).join('');
    }

    static toUInt8Array(value: string){
        var result = [];
        for(var i = 0; i < value.length; i+=2)
        {
            result.push(parseInt(value.substring(i, i + 2), 16));
        }
        return Uint8Array.from(result)
    }

    static removeNulls(str : string) : string{
        return str.replace(/\u0000/gi, "")
    }

   
    
}// byteUtil