import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import { Page } from 'src/app/models/page.model';
import { CDR } from 'src/app/models/cdr.model';
import { CdrService } from '../../service/cdr.service';
import { MatDialog } from '@angular/material/dialog';
import { CdrDetailsComponent } from '../cdr-details/cdr-details.component';
import { FilterAndPagination } from 'src/app/models/FilterAndPagination.model';
import { UtilService } from 'src/app/services/util.service';
import { CdrEvseConnectorTariffComponent } from '../cdr-evse-connector-tariff/cdr-evse-connector-tariff.component';
import { DomainService } from 'src/app/services/domain.service';

@Component({
  selector: 'app-cdr',
  templateUrl: './cdr.component.html',
  styleUrls: ['./cdr.component.css']
})
export class CdrComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    'partyId', 'countryCode', 'authMethod', 'currency', 'totalCost',
    'totalEnergy', 'remark', 'evseId', 'connectorId', 'city', 'endDateTime', 'actions'
  ];
  dataSource = new MatTableDataSource<CDR>();
  totalElements: number = 0;
  pageSize: number = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;

  partyIdFilter = new FormControl();
  countryCodeFilter = new FormControl();
  authMethodFilter = new FormControl();
  currencyFilter = new FormControl();
  totalCostFilter = new FormControl();
  totalEnergyFilter = new FormControl();
  remarkFilter = new FormControl();
  evseIdFilter = new FormControl();
  connectorIdFilter = new FormControl();
  cityFilter = new FormControl();
  endDateTimeFilter = new FormControl();
  domainFilter: string = "";

  filterAndPagination: FilterAndPagination = {
    orderDirection: 'DESC',
    orderColumn: 'partyId',
    filterGeneric: [],
    page: 0,
    limit: 0,
    pagIndexStart: 0,
    pagIndexEnd: environment.defaultPaginacion
  };

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  constructor(
    private cdrService: CdrService,
    private utilService: UtilService,
    public dialog: MatDialog,
    private domainService: DomainService,
    private cdr: ChangeDetectorRef

  ) {}

  ngOnInit(): void {
    this.loadCdrs();
    // Suscribirse a los cambios en los filtros
    this.domainService.valueChanged$.subscribe((newValue) => {
      this.domainFilter = newValue; // Update the received value
      this.applyFilters();
    });
    this.partyIdFilter.valueChanges.subscribe(() => this.applyFilters());
    this.countryCodeFilter.valueChanges.subscribe(() => this.applyFilters());
    this.authMethodFilter.valueChanges.subscribe(() => this.applyFilters());
    this.currencyFilter.valueChanges.subscribe(() => this.applyFilters());
    this.totalCostFilter.valueChanges.subscribe(() => this.applyFilters());
    this.totalEnergyFilter.valueChanges.subscribe(() => this.applyFilters());
    this.remarkFilter.valueChanges.subscribe(() => this.applyFilters());
    this.evseIdFilter.valueChanges.subscribe(() => this.applyFilters());
    this.connectorIdFilter.valueChanges.subscribe(() => this.applyFilters());
    this.cityFilter.valueChanges.subscribe(() => this.applyFilters());
    this.endDateTimeFilter.valueChanges.subscribe(() => this.applyFilters());
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.sort.sortChange.subscribe((sortState: Sort) => {
      this.filterAndPagination.orderColumn = sortState.active;
      this.filterAndPagination.orderDirection = sortState.direction ? sortState.direction.toUpperCase() : 'ASC';
      this.loadCdrs();
    });
  }

  applyFilters(): void {
    const filters = [];

    if (this.partyIdFilter.value) {
      filters.push({ column: 'partyId', value: this.partyIdFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.countryCodeFilter.value) {
      filters.push({ column: 'countryCode', value: this.countryCodeFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.authMethodFilter.value) {
      filters.push({ column: 'authMethod', value: this.authMethodFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.currencyFilter.value) {
      filters.push({ column: 'currency', value: this.currencyFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.totalCostFilter.value) {
      filters.push({ column: 'totalCost', value: this.totalCostFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.totalEnergyFilter.value) {
      filters.push({ column: 'totalEnergy', value: this.totalEnergyFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.remarkFilter.value) {
      filters.push({ column: 'remark', value: this.remarkFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.evseIdFilter.value) {
      filters.push({ column: 'cdrLocation.evseId', value: this.evseIdFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.connectorIdFilter.value) {
      filters.push({ column: 'cdrLocation.connectorId', value: this.connectorIdFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.cityFilter.value) {
      filters.push({ column: 'cdrLocation.city', value: this.cityFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.endDateTimeFilter.value) {
      filters.push({ column: 'lastUpdated', value: this.utilService.formatDateToISOString(this.endDateTimeFilter.value), operation: 'GREATER_THAN_OR_EQUAL', type: 'DATE' });
    }
    if (this.domainFilter) {
      filters.push({ column: 'domain', value: this.domainFilter, operation: 'LIKE', type: 'STRING' });
    }
    this.filterAndPagination.filterGeneric = filters;

    this.loadCdrs();
  }

  loadCdrs(event?: PageEvent): void {
    this.filterAndPagination.page = event ? event.pageIndex : 0;
    this.filterAndPagination.limit = event ? event.pageSize : this.pageSize;
    this.filterAndPagination.pagIndexStart = this.filterAndPagination.page * this.filterAndPagination.limit;
    this.filterAndPagination.pagIndexEnd = this.filterAndPagination.pagIndexStart + this.filterAndPagination.limit;

    this.cdrService.findCdrs(this.filterAndPagination).subscribe((page: Page<CDR>) => {
      this.dataSource.data = page.content;
      this.totalElements = page.totalElements;
      this.pageSize = page.size;

      if (this.paginator) {
        this.paginator.length = this.totalElements;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
      }
    });
  }


  private convertToBoolean(value: any): boolean | null {
    if (value === null || value === undefined || value === '') {
      return null;
    }
    return value === 'true' || value === true;
  }

  openDialog(cdr: CDR): void {
    const dialogRef = this.dialog.open(CdrDetailsComponent, {
      width: '70%', height: '80%', panelClass: 'custom-modalbox',
      data: cdr
    });

    dialogRef.afterClosed().subscribe(() => {
      this.loadCdrs();
    });
  }


  openDialogCharacteristics(cdr: CDR, onOpen: string): void {
    const dialogRef = this.dialog.open(CdrEvseConnectorTariffComponent, {
      width: '70%', height: '80%', panelClass: 'custom-modalbox',
      data: { cdr, onOpen }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.loadCdrs();
    });
  }

  resetEndDateTimeFilter(): void {
    this.endDateTimeFilter.setValue(null);
  }

}
