import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LocationService } from '../../../locations/services/location.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Page } from 'src/app/models/page.model';
import { Location } from 'src/app/models/location.model';
import { DualListComponent } from 'src/app/shared/components/dual-listbox/dual-list.component';
import { EVSE } from 'src/app/models/evse.model';
import { TerminalService } from '../../services/terminal.service';
import { LocationDefinition, LocationStatus, Terminal } from 'src/app/models/terminal.model';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-terminal',
  templateUrl: './add-terminal.component.html',
  styleUrls: ['./add-terminal.component.css']
})
export class AddTerminalComponent {
  selectedTabIndex: number = 0; 
  tabStates: boolean[] = [false, true, true]; 
  pendingRegister = 'PENDING_REGISTER';
  statusList: string[] = [];
  terminalForm!: FormGroup;
  translations: any;
  evses: EVSE[] = [];
  locations: Location[] = [];
  listaResultSelect: Location[] = [];
  formartDualList = { add: 'Add', remove: 'Remove', all: 'All', none: 'None',
    direction: DualListComponent.LTR, draggable: true, locale: undefined };
    displayDualList: any = ['locationId','name']; 
    keyDualList: any = 'locationId'; 

  listaResultSelectEvse: EVSE[] = [];
  displayDualListEvse: any = ['evseId','uid']; 
  keyDualListEvse: any = 'evseId';

  constructor(
    private fb: FormBuilder,public dialogRef: MatDialogRef<AddTerminalComponent>,
    private locationService: LocationService, private authService: AuthService,
    private terminalService: TerminalService,    private notifier: NotifierService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.terminalForm = this.fb.group({
      status: new FormControl({ value: '', disabled: true }, Validators.required),
      preAuthorizationAmount: new FormControl('', [
        Validators.pattern('^[0-9]+(\\.[0-9]{1,2})?$')  
      ])    });
    this.loadLocations();
    this.loadStatuses();
    
    this.translate
      .get([
        "TERMINAL"
      ])
      .subscribe(translation => {
        this.translations = translation.TERMINAL.STATUS;
        this.statusList = ['', ...Object.keys(translation.USERS.STATUS)];
      });

  }

   findLocationsByIds(): void {
    const selectedIds = new Set(this.listaResultSelect.map(location => location.locationId));
    const matchingLocations = this.locations.filter(location => selectedIds.has(location.locationId));
  
this.listaResultSelect = Array.from(
  new Map(matchingLocations.map(location => [location.locationId, location])).values()
);  }

  findEvsesByIds(): void {
    const selectedIds = new Set(this.listaResultSelectEvse.map(evse => evse.evseId));
    const matchingEvses = this.evses.filter(evse => selectedIds.has(evse.evseId));
  this.listaResultSelectEvse = Array.from(
    new Map(matchingEvses.map(evse => [evse.evseId, evse])).values()
  );
  }

  isTabDisabled(index: number): boolean {
    return this.tabStates[index];
  }

  loadLocations(): void {
    const requestParams: any = {};
  
    this.locationService.findLocations(requestParams).subscribe((page: Page<Location>) => {
      this.locations = page.content;
    });
  }

  getEVSEs(): void {
    this.evses = this.listaResultSelect.flatMap(location => location.evses);
  }

  loadStatuses(): void {
    this.terminalService.getStatusesTerminals().subscribe({
      next: (statuses: string[]) => {
        this.statusList = statuses.filter(status => status === 'PENDING_REGISTER');
        if (this.statusList.length === 1) {
          this.terminalForm.get('status')?.setValue(this.statusList[0]);
        }
      },
      error: (err) => {
        console.error('Error loading statuses:', err);
      }
    });
  }

  onFormSubmitLocations(): void {
    this.findLocationsByIds();
    this.getEVSEs();
    this.selectedTabIndex = 1;
    this.tabStates[0] = true; 
    this.tabStates[1] = false; 

  }

  onFormSubmitEvses(): void {
    this.findEvsesByIds();
    this.selectedTabIndex = 2;
    this.tabStates[1] = true; 
    this.tabStates[2] = false;
  }


    onFormSubmit(): void {

      if (this.listaResultSelect.length < 1) {
        this.notifier.notify('warning', 'No locations selected.');  
        return;
      }
      
      if (this.listaResultSelectEvse.length < 1) {
        this.notifier.notify('warning', 'No EVSEs selected.');  
        return;
      }
      
      if (this.terminalForm.invalid) {
        this.notifier.notify('warning', 'Form is invalid.');  
        return;
      }

      this.terminalService.preregistrationTerminal(this.createTerminal()).subscribe({
        next: (_: Terminal) => {
          this.dialogRef.close();
          this.notifier.notify('success', 'Preregistration Successfull');  
        },
        error: (err) => {
          this.notifier.notify('error', 'Error preregistration Terminal:', err);  

          console.error('Error preregistration Terminal:', err);
        }
      });
    }
  


  onClose(): void {
    console.log(this.listaResultSelectEvse)
    this.dialogRef.close();
  }
  onBackLocations(): void {
    this.selectedTabIndex = 0;
    this.tabStates[0] = false; 
    this.tabStates[1] = true; 
  }

  onBackEvses(): void {
    this.selectedTabIndex = 1;
    this.tabStates[1] = false; 
    this.tabStates[2] = true; 
  }


  createTerminal(): {} {
    const locations = this.listaResultSelect; 
    const evses = this.listaResultSelectEvse; 
  
  
    const evseIdsSet = new Set(evses.map(evse => evse.evseId));
  
    const updatedLocations = locations.map(location => {
      const matchingEvses = location.evses.filter(evse => evseIdsSet.has(evse.evseId));  
      return {
        ...location,
        evses: matchingEvses, 
      };
    });
  
  
    const locationDefinitions: LocationDefinition[] = updatedLocations.map(location => {
      const evseUids = location.evses.map(evse => evse.uid);
  
      return {
        locationId: location.locationId,
        evseUids: evseUids
      };
    });
  
    let locationStatuses: LocationStatus[] = updatedLocations.map(location => {
      const evseStatus: { [key: string]: string } = {};
  
      location.evses.forEach(evse => {
        evseStatus[evse.uid] = evse.status;
      });
  
      return {
        locationId: location.locationId,
        evseStatus: evseStatus
      };
    });

    const status = this.terminalForm.get('status')?.value;
    const preAuthorizationAmount = Number(this.terminalForm.get('preAuthorizationAmount')?.value) || 50;
  

    const terminal = {
      countryCode: updatedLocations[0]?.countryCode || '', 
      partyId: updatedLocations[0]?.partyId || '',
      services: locationDefinitions,
      servicesStatus: locationStatuses,
      capabilities: '', 
      domain: this.authService.domain, 
      status: status , 
      preAuthorizationAmount:  preAuthorizationAmount ,
    };
  
    return terminal;
  }

}


