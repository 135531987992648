import { AfterViewInit, Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import { ClientService } from '../../services/client.service';
import { Client } from 'src/app/models/clients.model';
import { Page } from 'src/app/models/page.model';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { UtilService } from 'src/app/services/util.service';
import { TranslateService } from '@ngx-translate/core';
import { NewClientComponent } from '../new-client/new-client.component';
import { MatDialog } from '@angular/material/dialog';
import { FilterAndPagination } from 'src/app/models/FilterAndPagination.model';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['partyId', 'countryCode', 'role', 'status', 'lastUpdated', 'actions'];
  dataSource = new MatTableDataSource<Client>();
  roles: string[] = [];
  statuses: string[] = [];
  totalElements: number = 0; // Mantiene el total de elementos
  pageSize: number = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;

  partyIdFilter = new FormControl();
  countryCodeFilter = new FormControl();
  roleFilter = new FormControl();
  statusFilter = new FormControl();

  lastUpdatedFilter = new FormControl();

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  translations: any;

  filterAndPagination: FilterAndPagination = {
    orderDirection: 'DESC',
    orderColumn: 'partyId',
    filterGeneric: [],
    page: 0,
    limit: 0,
    pagIndexStart: 0,
    pagIndexEnd: environment.defaultPaginacion
  };

  constructor(
    private clientService: ClientService,
    private utilService: UtilService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef // Para detectar cambios si es necesario
  ) {}

  ngOnInit(): void {
    this.loadClients();
    this.loadRoles();
    this.loadStatuses();

    // Suscribirse a los cambios en los filtros
    this.partyIdFilter.valueChanges.subscribe(() => this.applyFilters());
    this.countryCodeFilter.valueChanges.subscribe(() => this.applyFilters());
    this.roleFilter.valueChanges.subscribe(() => this.applyFilters());
    this.statusFilter.valueChanges.subscribe(() => this.applyFilters());
    this.lastUpdatedFilter.valueChanges.subscribe(() => this.applyFilters());

    this.translate.get(['CLIENT']).subscribe((translation) => {
      this.translations = {
        ...translation.CLIENT.ROLE,
        ...translation.CLIENT.STATUS
      };
    });
  }

  ngAfterViewInit(): void {
    // Usar ChangeDetectorRef para asegurar que paginator y sort están inicializados
    this.cdr.detectChanges();

    // Asignar paginador y sorter a la dataSource
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.sort.sortChange.subscribe((sortState: Sort) => {
      this.filterAndPagination.orderColumn = sortState.active;
      this.filterAndPagination.orderDirection = sortState.direction ? sortState.direction.toUpperCase() : 'ASC';
      this.loadClients();
    });
  }

  sortData(sortState: Sort) {
    this.filterAndPagination.orderColumn = sortState.active;
    this.filterAndPagination.orderDirection = sortState.direction ? sortState.direction.toUpperCase() : 'ASC';
    this.loadClients();
  }

  applyFilters(): void {
    // Crear un array de filtros formateado correctamente
    const filters = [];

    if (this.partyIdFilter.value) {
      filters.push({ column: 'partyId', value: this.partyIdFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.countryCodeFilter.value) {
      filters.push({ column: 'countryCode', value: this.countryCodeFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.roleFilter.value) {
      filters.push({ column: 'role', value: this.roleFilter.value, operation: 'EQUALS', type: 'STRING' });
    }
    if (this.statusFilter.value) {
      filters.push({ column: 'status', value: this.statusFilter.value, operation: 'EQUALS', type: 'STRING' });
    }
    if (this.lastUpdatedFilter.value) {
      filters.push({ column: 'lastUpdated', value: this.utilService.formatDateToISOString(this.lastUpdatedFilter.value), operation: 'GREATER_THAN_OR_EQUAL', type: 'DATE' });
    }

    this.filterAndPagination.filterGeneric = filters;

    this.loadClients();
  }

  loadClients(event?: PageEvent): void {
    this.filterAndPagination.page = event ? event.pageIndex : 0;
    this.filterAndPagination.limit = event ? event.pageSize : this.pageSize;
    this.filterAndPagination.pagIndexStart = this.filterAndPagination.page * this.filterAndPagination.limit;
    this.filterAndPagination.pagIndexEnd = this.filterAndPagination.pagIndexStart + this.filterAndPagination.limit;

    this.clientService.findClients(this.filterAndPagination).subscribe((page: Page<Client>) => {
      this.dataSource.data = page.content;
      this.totalElements = page.totalElements;
      this.pageSize = page.size;

      // Verificar que paginator no sea undefined
      if (this.paginator) {
        this.paginator.length = this.totalElements;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
      }
    });
  }

  resetLastUpdatedFilter(): void {
    this.lastUpdatedFilter.setValue(null);
  }

  loadRoles(): void {
    this.clientService.getRoleClients().subscribe((roles: string[]) => {
      this.roles = roles;
    });
  }

  loadStatuses(): void {
    this.clientService.getStatusClients().subscribe((statuses: string[]) => {
      this.statuses = statuses;
    });
  }

  onNew() {
    const dialogRef = this.dialog.open(NewClientComponent, {
      width: '70%',
      height: '80%',
      panelClass: 'custom-modalbox',
    });

    dialogRef.afterClosed().subscribe(() => {
      this.loadClients();
    });
  }

  openDialog(client: Client): void {
    const dialogRef = this.dialog.open(NewClientComponent, {
      width: '70%',
      height: '80%',
      panelClass: 'custom-modalbox',
      data: { client: client }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.loadClients();
    });
  }
}
