<div class="pagetitle">
    <h1 i18n>Línea Temporal: {{ eventAuthorizationId }}</h1>
  </div>

<div class="timeline">
  <div class="timeline-line" [ngStyle]="{'background': getGradientStyle()}"></div>

  <div
    class="timeline-item"
    *ngFor="let event of events"
    [ngClass]="{
      'info-background': event.status === 'INFO',
      'error-background': event.status === 'ERROR',
      'warning-background': event.status === 'WARNING'
    }"
  >
    <div
      class="timeline-content"
      [ngClass]="{
        'info-background': event.status === 'INFO',
        'error-background': event.status === 'ERROR',
        'warning-background': event.status === 'WARNING'
      }"
    >
      <div class="pagetitle">
        <h3>{{ event.createdAt | date: 'yyyy-MM-dd HH:mm:ss' }}</h3>
      </div>
      <h5>
        <ul class="horizontal-list">
          <li>{{ event.type }}</li>
          <li>{{ event.command }}</li>
        </ul>
      </h5>
      <p>{{ event.message }}</p>
    </div>
  </div>
</div>