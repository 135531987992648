import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, shareReplay } from 'rxjs';
import { Client } from 'src/app/models/clients.model';
import { FilterAndPagination } from 'src/app/models/FilterAndPagination.model';
import { Page } from 'src/app/models/page.model';
import { handleError } from 'src/app/shared/util/handelError';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class ClientService {

  private roleClients$!: Observable<string[]>;
  private statusClients$!: Observable<string[]>;
  private readonly apiUrl = environment.apiUrl;

  constructor(private readonly http: HttpClient) {
    this.initialize();
  }
  


  findClients(filterAndPagination: FilterAndPagination): Observable<Page<Client>> {
    return this.http.post<Page<Client>>(`${this.apiUrl}/client`, filterAndPagination)
      .pipe(
        catchError(handleError<Page<Client>>('findClients', { content: [], totalPages: 0, totalElements: 0, number: 0, size: 0 }))
      );
  }

  saveClient(clientRequest: Client): Observable<Client> {
    return this.http.post<Client>(`${this.apiUrl}/client/save`, clientRequest)
      .pipe(
        catchError(handleError<Client>('saveClient'))
      );
  }



  private initialize(): void {
    this.roleClients$ = this.http.get<string[]>(`${this.apiUrl}/client/role`)
      .pipe(
        catchError(handleError<string[]>('fetching client roles', [])),
        shareReplay(1)
      );

    this.statusClients$ = this.http.get<string[]>(`${this.apiUrl}/client/status`)
      .pipe(
        catchError(handleError<string[]>('fetching client status', [])),
        shareReplay(1)
      );

  }

  getRoleClients(): Observable<string[]> {
    return this.roleClients$;
  }

  getStatusClients(): Observable<string[]> {
    return this.statusClients$;
  }


}
