import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CDR } from 'src/app/models/cdr.model';
import { getTariffIds, toSingleElementArray } from 'src/app/shared/util/util';

@Component({
  selector: 'app-cdr-evse-connector-tariff',
  templateUrl: './cdr-evse-connector-tariff.component.html',
  styleUrls: ['./cdr-evse-connector-tariff.component.css']
})
export class CdrEvseConnectorTariffComponent {
  constructor(
    public dialogRef: MatDialogRef<CdrEvseConnectorTariffComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  connectorsIds: string[] = [];
  tariffsIds: string[] = [];
  evsesIds: string[] = [];
  onOpen: string = '';
  selectedTabIndex: number = 0;

  ngOnInit(): void {
    
    this.tariffsIds = getTariffIds(this.data.cdr.tariffs);
    this.connectorsIds = this.data.cdr.cdrLocation.connectorId 
    ? toSingleElementArray(this.data.cdr.cdrLocation.connectorId) 
    : [];

  this.evsesIds = this.data.cdr.cdrLocation.evseId 
    ? toSingleElementArray(this.data.cdr.cdrLocation.evseUid) 
    : [];
    this.onOpen = this.data.onOpen;
    this.selectedTabIndex = this.getTabIndex(this.onOpen);
  }

  getTabIndex(onOpen: string): number {
    switch (onOpen) {
      case 'evse':
        return 0; 
      case 'connector':
        return 1; 
      case 'tariff':
        return 2; 
      default:
        return 0;
    }
  }


  onClose(): void {
    this.dialogRef.close();
  }
}
