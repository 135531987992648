
<div class="pagetitle">
    <h1 i18n>{{"Invoice" | translate }}</h1>  
</div>
<section class="section">

<mat-tab-group [formGroup]="cdrForm">
    <mat-tab [label]="'Identification' | translate">
        <div class="row ajuste-contenedor-tab">

            <div class="col-lg-12">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Invoice" | translate }}</h5>
                         <input formControlName="CDRId" matInput type="text">
                     </mat-form-field>
                 </div>
             </div>

            <div class="col-lg-6"> 
               <div class="card cardCustos">
                   <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Party ID" | translate }}</h5>
                        <input formControlName="partyId" matInput type="text">
                    </mat-form-field>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Country Code" | translate }}</h5>
                         <input formControlName="countryCode" matInput type="text">
                     </mat-form-field>
                 </div>
             </div>
 
             <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Charging point ID" | translate }}</h5>
                         <input formControlName="evseId" matInput type="text">
                     </mat-form-field>
                 </div>
             </div>

             <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Connector ID" | translate }}</h5>
                         <input formControlName="ConnectorId" matInput type="text">
                     </mat-form-field>
                 </div>
             </div>
           
        </div>

           </mat-tab>
    <mat-tab [label]="'Authentication' | translate">
        <div class="row ajuste-contenedor-tab">

            <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Auth Method" | translate }}</h5>
                         <input formControlName="authMethod" matInput type="text">
                     </mat-form-field>
                 </div>
             </div>

             <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Auth Reference" | translate }}</h5>
                         <input formControlName="authReference" matInput type="text">
                     </mat-form-field>
                 </div>
             </div>
        </div>
    </mat-tab>
    <mat-tab [label]="'Total Costs' | translate">
        <div class="row ajuste-contenedor-tab">

            <div class="col-lg-3">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Total Cost" | translate }}</h5>
                         <input formControlName="authMethod" matInput type="text">
                     </mat-form-field>
                 </div>
             </div>

             <div class="col-lg-3">
                <div class="card cardCustos ">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Total Fixed Cost" | translate }}</h5>
                         <input formControlName="totalFixedCost" matInput type="text">
                     </mat-form-field>
                 </div>
             </div>
             <div class="col-lg-3">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Total Energy Cost" | translate }}</h5>
                         <input formControlName="totalEnergyCost" matInput type="text">
                     </mat-form-field>
                 </div>
             </div><div class="col-lg-3">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Total Time Cost" | translate }} </h5>
                         <input formControlName="totalTimeCost" matInput type="text">
                     </mat-form-field>
                 </div>
             </div><div class="col-lg-3">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Total Parking Cost" | translate }}</h5>
                         <input formControlName="totalParkingCost" matInput type="text">
                     </mat-form-field>
                 </div>
             </div><div class="col-lg-3">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Total Reservation Cost" | translate }}</h5>
                         <input formControlName="totalReservationCost" matInput type="text">
                     </mat-form-field>
                 </div>
             </div><div class="col-lg-3">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Currency" | translate }}Currency</h5>
                         <input formControlName="currency" matInput type="text">
                     </mat-form-field>
                 </div>
             </div>
        </div>
    </mat-tab>
    <mat-tab [label]="'Energy and Time' | translate">
        <div class="row ajuste-contenedor-tab">

            <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Total Energy" | translate }}</h5>
                         <input formControlName="totalEnergy" matInput type="text">
                     </mat-form-field>
                 </div>
             </div>
             <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Total Time" | translate }}</h5>
                         <input formControlName="totalTime" matInput type="text">
                     </mat-form-field>
                 </div>
             </div>
             <div class="col-lg-12">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Total Parking Time" | translate }}</h5>
                         <input formControlName="totalParkingTime" matInput type="text">
                     </mat-form-field>
                 </div>
             </div>
        </div>
    </mat-tab>
    <mat-tab [label]="'Observations and References' | translate">
        <div class="row ajuste-contenedor-tab">

            <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Remark" | translate }}</h5>
                         <input formControlName="remark" matInput type="text">
                     </mat-form-field>
                 </div>
             </div>

             <div class="col-lg-12">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Invoice Reference ID" | translate }}</h5>
                         <input formControlName="invoiceReferenceId" matInput type="text">
                     </mat-form-field>
                 </div>
             </div>
        </div>
    </mat-tab>
    <mat-tab [label]="'Credits and Compensations' | translate">
        <div class="row ajuste-contenedor-tab">

            <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Has Credit" | translate }}</h5>
                         <input formControlName="hasCredit" matInput type="text">
                     </mat-form-field>
                 </div>
             </div>

             <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Credit Reference ID" | translate }}</h5>
                         <input formControlName="creditReferenceId" matInput type="text">
                     </mat-form-field>
                 </div>
             </div>

             <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Home Charging Compensation" | translate }}</h5>
                         <input formControlName="homeChargingCompensation" matInput type="text">
                     </mat-form-field>
                 </div>
             </div>
        </div>
         
    </mat-tab>
  </mat-tab-group>
</section>