import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { FilterAndPagination } from 'src/app/models/FilterAndPagination.model';
import { ManagementCpo } from 'src/app/models/managementCpo.model';
import { Page } from 'src/app/models/page.model';
import { handleError } from 'src/app/shared/util/handelError';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class CredentialsService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  findCredentials(filterAndPagination: FilterAndPagination): Observable<Page<ManagementCpo>> {
    return this.http.post<Page<ManagementCpo>>(`${this.apiUrl}/credential`, filterAndPagination)
      .pipe(
        catchError(handleError<Page<ManagementCpo>>('findCredentials', { content: [], totalPages: 0, totalElements: 0, number: 0, size: 0 }))
      );
  }

  saveCredential(credentialRequest: ManagementCpo): Observable<ManagementCpo> {
    return this.http.post<ManagementCpo>(`${this.apiUrl}/credential/save`, credentialRequest)
      .pipe(
        catchError(handleError<ManagementCpo>('saveCredential'))
      );
  }




}
