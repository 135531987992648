<aside id="sidebar" class="sidebar personalMenu {{isEntornoXiibero}}">
   <ul class="sidebar-nav" id="sidebar-nav">

      <li class="nav-item hovered_navItem" id="toc_li_Home" data-level="1" (click)="marcarMenu('toc_cb_Home')">
         <input id="toc_cb_Home" class="toc" type="checkbox">
         <label for="toc_cb_Home">
               <a class="nav-link nav-link-no-pulsable" routerLink="/dashboard">
                  <i class="bi bi-grid"></i> 
                  <span i18n>{{"Dashboard" | translate }}</span> 
               </a>
         </label>
      </li>

      <li class="nav-item" id="toc_li_Views" data-level="1" *ngIf="checkMenuAccessShown()">        
            <!-- <a class="nav-link collapsed" data-bs-target="#Views-nav" data-bs-toggle="collapse" href="#"> 
               <i class="bi bi-layout-text-window-reverse"></i><span i18n>{{"Views" | translate }}</span><i class="bi bi-chevron-down ms-auto"></i>
            </a> -->
         <div class="nav-link-no-pulsable">
            <i class="bi bi-layout-text-window-reverse">&nbsp;</i><span i18n>{{"Views" | translate }}</span>
         </div>
         <ul id="Views-nav" class="nav-content " data-bs-parent="#sidebar-nav">           
            <!-- <li id="toc_li_organizations" data-level="2"  (click)="marcarMenu('toc_cb_organizations')" *ngIf="checkMenuAccess('organization')">
               <input id="toc_cb_organizations" class="toc" type="checkbox">
               <label for="toc_cb_organizations">
                  <a routerLink="/organizations"> <i class="bi bi-building"></i><span i18n>{{"Organizations" | translate }}</span> </a>
               </label>
            </li>


            <li id="toc_li_region" data-level="2"  (click)="marcarMenu('toc_cb_region')" *ngIf="checkMenuAccess('region') && isJerarquiaRegion">
               <input id="toc_cb_region" class="toc" type="checkbox">
               <label for="toc_cb_region">
                  <a routerLink="/region"> <i class="bi bi-bank"></i><span i18n>{{"Regions" | translate }}</span> </a>
               </label>
            </li>

            <li id="toc_li_division" data-level="2"  (click)="marcarMenu('toc_cb_division')" *ngIf="checkMenuAccess('division')  &&  isJerarquiaDivision">
               <input id="toc_cb_division" class="toc" type="checkbox">
               <label for="toc_cb_division">
                  <a routerLink="/division"> <i class="bi bi-shop-window"></i><span i18n>{{"Divisions" | translate }}</span> </a>
               </label>
            </li>
            
            <li id="toc_li_merchants" data-level="2"  (click)="marcarMenu('toc_cb_merchants')" *ngIf="checkMenuAccess('merchant')">
               <input id="toc_cb_merchants" class="toc" type="checkbox">
               <label for="toc_cb_merchants">
                  <a routerLink="/merchants"> <i class="bi bi-shop"></i><span i18n>{{"Merchants" | translate }}</span> </a>
               </label>
            </li>


            <li id="toc_li_subsidiary" data-level="2"  (click)="marcarMenu('toc_cb_subsidiary')" *ngIf="checkMenuAccess('merchant')  && isJerarquiaSubsidiary ">
               <input id="toc_cb_subsidiary" class="toc" type="checkbox">
               <label for="toc_cb_subsidiary">
                  <a routerLink="/subsidiary"> <i class="bi bi-bag"></i><span i18n>{{"Subsidiaries" | translate }}</span> </a>
               </label>
            </li>


             -->


            <li id="toc_li_client" data-level="2"  (click)="marcarMenu('toc_cb_client')" *ngIf="checkMenuAccess('client')">
               <input id="toc_cb_client" class="toc" type="checkbox">
               <label for="toc_cb_client">
                  <a routerLink="/client"> <i class="bi bi-person-fill"></i><span i18n>{{"Clients" | translate }}</span> </a>
               </label>
            </li>

            <li id="toc_li_tariff" data-level="2"  (click)="marcarMenu('toc_cb_tariff')" *ngIf="checkMenuAccess('tariff')">
               <input id="toc_cb_tariff" class="toc" type="checkbox">
               <label for="toc_cb_tariff">
                  <a routerLink="/tariff"> <i class="bi bi-bank"></i><span i18n>{{"Tariffs" | translate }}</span> </a>
               </label>
            </li>

            <li id="toc_li_locations" data-level="2"  (click)="marcarMenu('toc_cb_locations')" *ngIf="checkMenuAccess('location')">
               <input id="toc_cb_locations" class="toc" type="checkbox">
               <label for="toc_cb_locations">
                  <a routerLink="/location"> <i><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-globe-europe-africa" viewBox="0 0 16 16">
                     <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0M3.668 2.501l-.288.646a.847.847 0 0 0 1.479.815l.245-.368a.81.81 0 0 1 1.034-.275.81.81 0 0 0 .724 0l.261-.13a1 1 0 0 1 .775-.05l.984.34q.118.04.243.054c.784.093.855.377.694.801-.155.41-.616.617-1.035.487l-.01-.003C8.274 4.663 7.748 4.5 6 4.5 4.8 4.5 3.5 5.62 3.5 7c0 1.96.826 2.166 1.696 2.382.46.115.935.233 1.304.618.449.467.393 1.181.339 1.877C6.755 12.96 6.674 14 8.5 14c1.75 0 3-3.5 3-4.5 0-.262.208-.468.444-.7.396-.392.87-.86.556-1.8-.097-.291-.396-.568-.641-.756-.174-.133-.207-.396-.052-.551a.33.33 0 0 1 .42-.042l1.085.724c.11.072.255.058.348-.035.15-.15.415-.083.489.117.16.43.445 1.05.849 1.357L15 8A7 7 0 1 1 3.668 2.501"/>
                   </svg></i><span i18n>{{"Locations" | translate }}</span> </a>
               </label>
            </li>

            <li id="toc_li_incidence" data-level="2"  (click)="marcarMenu('toc_cb_incidence')" *ngIf="checkMenuAccess('incidence')">
               <input id="toc_cb_incidence" class="toc" type="checkbox">
               <label for="toc_cb_incidence">
                  <a routerLink="/incidence"> <i class="bi bi-shield-exclamation"></i><span i18n>{{"Incidents" | translate }}</span> </a>
               </label>
            </li>

            <li id="toc_li_managementCpo" data-level="2"  (click)="marcarMenu('toc_cb_managementCpo')" *ngIf="checkMenuAccess('credential')">
               <input id="toc_cb_managementCpo" class="toc" type="checkbox">
               <label for="toc_cb_managementCpo">
                  <a routerLink="/managementCpo"> <i class="bi bi-gear-wide-connected"></i><span i18n>{{"CPO management" | translate }}</span> </a>
               </label>
            </li>

            <li id="toc_li_connector" data-level="2"  (click)="marcarMenu('toc_cb_connector')" *ngIf="checkMenuAccess('connector')">
               <input id="toc_cb_connector" class="toc" type="checkbox">
               <label for="toc_cb_connector">
                  <a routerLink="/connector"> <i class="bi bi-plug"></i><span i18n>{{"Connectors" | translate }}</span> </a>
               </label>
            </li>

            <li id="toc_li_cdr" data-level="2"  (click)="marcarMenu('toc_cb_cdr')" *ngIf="checkMenuAccess('cdr')">
               <input id="toc_cb_cdr" class="toc" type="checkbox">
               <label for="toc_cb_cdr">
                  <a routerLink="/cdr"> <i class="bi bi-archive"></i><span i18n>{{"Invoices" | translate }}</span> </a>
               </label>
            </li>

            
            <li id="toc_li_authorization" data-level="2"  (click)="marcarMenu('toc_cb_authorization')" *ngIf="checkMenuAccess('authorization')">
               <input id="toc_cb_authorization" class="toc" type="checkbox">
               <label for="toc_cb_authorization">
                  <a routerLink="/authorization"> <i class="bi bi-x-diamond"></i><span i18n>{{"Authorizations" | translate }}</span> </a>
               </label>
            </li>

            <li id="toc_li_evse" data-level="2"  (click)="marcarMenu('toc_cb_evse')" *ngIf="checkMenuAccess('evse')">
               <input id="toc_cb_evse" class="toc" type="checkbox">
               <label for="toc_cb_evse">
                  <a routerLink="/evse"> <i> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-ev-station" viewBox="0 0 16 16">
                     <path d="M3.5 2a.5.5 0 0 0-.5.5v5a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 .5-.5v-5a.5.5 0 0 0-.5-.5zm2.131 10.46H4.14v-.893h1.403v-.505H4.14v-.855h1.49v-.54H3.485V13h2.146zm1.316.54h.794l1.106-3.333h-.733l-.74 2.615h-.031l-.747-2.615h-.764z"/>
                     <path d="M3 0a2 2 0 0 0-2 2v13H.5a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1H11v-4a1 1 0 0 1 1 1v.5a1.5 1.5 0 0 0 3 0V4a.5.5 0 0 0-.146-.354l-.5-.5a.5.5 0 0 0-.707 0l-.5.5A.5.5 0 0 0 13 4v3c0 .71.38 1.096.636 1.357l.007.008c.253.258.357.377.357.635v3.5a.5.5 0 1 1-1 0V12a2 2 0 0 0-2-2V2a2 2 0 0 0-2-2zm7 2v13H2V2a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1"/>
                   </svg></i><span i18n>{{"Charging points" | translate }}</span> </a>
               </label>
            </li>

            
            
            <li id="toc_li_terminal" data-level="2"  (click)="marcarMenu('toc_cb_terminal')" *ngIf="checkMenuAccess('terminal')">
               <input id="toc_cb_terminal" class="toc" type="checkbox">
               <label for="toc_cb_terminal">
                  <a routerLink="/terminal"> <i class="bi bi-calculator"></i><span i18n>{{"Terminals" | translate }}</span> </a>
               </label>
            </li>


            <li id="toc_li_transaction" data-level="2"  (click)="marcarMenu('toc_cb_transaction')" *ngIf="checkMenuAccess('transaction')">
               <input id="toc_cb_transaction" class="toc" type="checkbox">
               <label for="toc_cb_transaction"  >
                  <a routerLink="/transaction"> <i class="bi bi-credit-card"></i><span i18n>{{"Transactions" | translate }}</span> </a>
               </label>
            </li>
            <li id="toc_li_alerts" data-level="2"  (click)="marcarMenu('toc_cb_alerts')" *ngIf="checkMenuAccess('notification')">
               <input id="toc_cb_alerts" class="toc" type="checkbox">
               <label for="toc_cb_alerts">
                  <a routerLink="/alerts"> <i class="bi bi-bell"></i><span i18n>{{"Alerts" | translate }}</span> </a>
               </label>
            </li>
            <li id="toc_li_orders" data-level="2"  (click)="marcarMenu('toc_cb_orders')" *ngIf="checkMenuAccess('terminal') && isEntornoXiibero">
               <input id="toc_cb_orders" class="toc" type="checkbox">
               <label for="toc_cb_orders">
                  <a routerLink="/orders"> <i class="bi bi-basket2"></i><span i18n>{{"Orders" | translate }}</span> </a>
               </label>
            </li>
         </ul>
      </li>

      <!-- <li class="nav-item hovered_navItem" *ngIf="checkUserAccessShown()">
         <a class="nav-link nav-link-no-pulsable collapsed" data-bs-target="#Admin-nav" data-bs-toggle="collapse" href="#"> 
            <i class="bi bi-shield-shaded"></i><span i18n>{{"Admin" | translate }}</span><i class="bi bi-chevron-down ms-auto"></i>
         </a>
         <ul id="Admin-nav" class="nav-content collapse" data-bs-parent="#sidebar-nav">
            <li *ngIf="checkUserAccess('user')" id="admin_user" (click)="marcarMenu('admin_user')" [ngClass]="{'selectedNavitem': selectedAdminItem === 'admin_user'}">
               <a routerLink="/users">
                  <i class="bi bi-person"></i> <span i18n>{{"Users" | translate }}</span>
               </a>
            </li>
            <li *ngIf="checkUserAccess('role')" id="admin_roles" (click)="marcarMenu('admin_roles')" [ngClass]="{'selectedNavitem': selectedAdminItem === 'admin_roles'}">
               <a routerLink="/rols">
                  <i class="bi bi-award"></i> <span i18n>{{"Roles" | translate }}</span>
               </a>
            </li>
            <li *ngIf="checkUserAccess('group')" id="admin_groups" (click)="marcarMenu('admin_groups')" [ngClass]="{'selectedNavitem': selectedAdminItem === 'admin_groups'}">
               <a routerLink="/groups">
                  <i class="bi bi-people"></i><span i18n>{{"Groups" | translate }}</span>
               </a>
            </li>
            <li *ngIf="checkMenuAccess('organization') && !isEntornoXiibero" id="admin_org" (click)="marcarMenu('admin_org')" [ngClass]="{'selectedNavitem': selectedAdminItem === 'admin_org'}">
               <a routerLink="/orgSetting">
                  <i class="bi bi-gear"></i><span i18n>{{"Settings" | translate }}</span>
               </a>
            </li>
         </ul>
      </li> -->

      <!-- components del framework - TODO: ELIMINAR      
       <li class="nav-item"> <a class="nav-link collapsed" routerLink="/pages-contact"> <i class="bi bi-envelope"></i> <span>Contact</span> </a></li>-->     

    </ul>
 </aside>
