import { AfterViewInit, Component, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import { Page } from 'src/app/models/page.model';
import { TerminalService } from '../../services/terminal.service';
import { Terminal } from 'src/app/models/terminal.model';
import { TerminalDetailsComponent } from '../terminal-details/terminal-details.component';
import { MatDialog } from '@angular/material/dialog';
import { AddTerminalComponent } from '../add-terminal/add-terminal.component';
import { TranslateService } from '@ngx-translate/core';
import { FilterAndPagination } from 'src/app/models/FilterAndPagination.model';
import { UtilService } from 'src/app/services/util.service';
import { DomainService } from 'src/app/services/domain.service';

@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.css']
})
export class TerminalComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['partyId', 'countryCode', 'model', 'language', 'preAuthorizationAmount', 'merchant', 'status', 'accions'];
  dataSource = new MatTableDataSource<Terminal>();
  statuses: string[] = [];
  totalElements: number = 0; // Total de elementos para paginación
  pageSize: number = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;

  partyIdFilter = new FormControl();
  countryCodeFilter = new FormControl();
  modelFilter = new FormControl();
  languageFilter = new FormControl();
  preAuthorizationAmountFilter = new FormControl();
  merchantFilter = new FormControl();
  statusFilter = new FormControl();
  
  filterAndPagination: FilterAndPagination = {
    orderDirection: 'DESC',
    orderColumn: 'partyId', 
    filterGeneric: [],
    page: 0,
    limit: 0,
    pagIndexStart: 0,
    pagIndexEnd: environment.defaultPaginacion
  };
  domainFilter: string = "";

  translations: any;

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  constructor(
    private terminalService: TerminalService, 
    private translate: TranslateService, 
    public dialog: MatDialog,
    public utilService: UtilService,
    private domainService: DomainService,
    private cdr: ChangeDetectorRef // Para detectar cambios si es necesario
  ) {}

  ngOnInit(): void {
    this.loadTerminals();
    this.loadStatuses();
    let value = this.utilService.cargarCacheDomain();
    console.log(value);
    // Suscribirse a los cambios en los filtros
    this.domainService.valueChanged$.subscribe((newValue) => {
      this.domainFilter = newValue; 
      this.applyFilters();
    });
    this.partyIdFilter.valueChanges.subscribe(() => this.applyFilters());
    this.countryCodeFilter.valueChanges.subscribe(() => this.applyFilters());
    this.modelFilter.valueChanges.subscribe(() => this.applyFilters());
    this.languageFilter.valueChanges.subscribe(() => this.applyFilters());
    this.preAuthorizationAmountFilter.valueChanges.subscribe(() => this.applyFilters());
    this.merchantFilter.valueChanges.subscribe(() => this.applyFilters());
    this.statusFilter.valueChanges.subscribe(() => this.applyFilters());

    // Traducciones
    this.translate.get(["TERMINAL"]).subscribe(translation => {
      this.translations = translation.TERMINAL.STATUS;
    });
  }

  ngAfterViewInit(): void {
    // Usar ChangeDetectorRef para asegurar que paginator y sort están inicializados
    this.cdr.detectChanges();

    // Asignar paginador y sorter a la dataSource
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // Suscribirse al cambio de ordenación
    this.sort.sortChange.subscribe((sortState: Sort) => {
      this.filterAndPagination.orderColumn = sortState.active;
      this.filterAndPagination.orderDirection = sortState.direction ? sortState.direction.toUpperCase() : 'ASC';
      this.loadTerminals();
    });
  }

  applyFilters(): void {
    const filters = []; // Define como un arreglo

    if (this.partyIdFilter.value) {
      filters.push({ column: 'partyId', value: this.partyIdFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.countryCodeFilter.value) {
      filters.push({ column: 'countryCode', value: this.countryCodeFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.modelFilter.value) {
      filters.push({ column: 'model', value: this.modelFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.languageFilter.value) {
      filters.push({ column: 'language', value: this.languageFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.preAuthorizationAmountFilter.value) {
      filters.push({ column: 'preAuthorizationAmount', value: this.preAuthorizationAmountFilter.value, operation: 'EQUALS', type: 'integer' });
    }
    if (this.merchantFilter.value) {
      filters.push({ column: 'merchant', value: this.merchantFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.statusFilter.value) {
      filters.push({ column: 'status', value: this.statusFilter.value, operation: 'EQUALS', type: 'STRING' });
    }
    if (this.domainFilter) {
      filters.push({ column: 'domain', value: this.domainFilter, operation: 'LIKE', type: 'STRING' });
    }
    this.filterAndPagination.filterGeneric = filters;

    this.loadTerminals();
  }

  loadTerminals(event?: PageEvent): void {
    this.filterAndPagination.page = event ? event.pageIndex : 0;
    this.filterAndPagination.limit = event ? event.pageSize : this.pageSize;
    this.filterAndPagination.pagIndexStart = this.filterAndPagination.page * this.filterAndPagination.limit;
    this.filterAndPagination.pagIndexEnd = this.filterAndPagination.pagIndexStart + this.filterAndPagination.limit;

    this.terminalService.findTerminals(this.filterAndPagination).subscribe((page: Page<Terminal>) => {
      this.dataSource.data = page.content;
      this.totalElements = page.totalElements;
      this.pageSize = page.size;

      // Verificar que paginator no sea undefined
      if (this.paginator) {
        this.paginator.length = this.totalElements;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
      }
    });
  }

  loadStatuses(): void {
    this.terminalService.getStatusesTerminals().subscribe((statuses: string[]) => {
      this.statuses = statuses;
    });
  }

  openDialog(terminal: Terminal): void {
    const dialogRef = this.dialog.open(TerminalDetailsComponent, {
      width: '70%', height: '80%', panelClass: 'custom-modalbox',
      data: terminal
    });

    dialogRef.afterClosed().subscribe(() => {
      this.loadTerminals();
    });
  }

  onNew(): void {
    const dialogRef = this.dialog.open(AddTerminalComponent, {
      width: '70%', height: '80%', panelClass: 'custom-modalbox',
    });

    dialogRef.afterClosed().subscribe(() => {
      this.loadTerminals();
    });
  }
}
