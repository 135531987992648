import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-authorization-details',
  templateUrl: './authorization-details.component.html',
  styleUrls: ['./authorization-details.component.css']
})
export class AuthorizationDetailsComponent {
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AuthorizationDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  authorizationForm!: FormGroup;
  
  ngOnInit() {
    this.authorizationForm = this.fb.group({
      partyId: [{ value: this.data.authorization.partyId, disabled: true }],
      countryCode: [{ value: this.data.authorization.countryCode, disabled: true }],
      status: [{ value: this.data.authorization.status, disabled: true }],
      serialNumber: [{ value: this.data.authorization.serialNumber, disabled: true }],
      message: [{ value: this.data.authorization.message, disabled: true }],
      lastUpdated: [{ value: this.data.authorization.lastUpdated, disabled: true }],
      locationId: [{ value: this.data.authorization.locationId, disabled: true }],
      evseId: [{ value: this.data.authorization.evseId, disabled: true }],
      connectorId: [{ value: this.data.authorization.connectorId, disabled: true }],
      language: [{ value: this.data.authorization.language, disabled: true }],
      authMethod: [{ value: this.data.authorization.authMethod, disabled: true }]
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
 