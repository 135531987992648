import { AfterViewInit, Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Incidence } from 'src/app/models/incidence.model';
import { Page } from 'src/app/models/page.model';
import { UtilService } from 'src/app/services/util.service';
import { environment } from 'src/environments/environment';
import { IncidenceService } from '../../services/incident.service';
import { MatDialog } from '@angular/material/dialog';
import { IncidenceDetailsComponent } from '../incidence-details/incidence-details.component';
import { TranslateService } from '@ngx-translate/core';
import { FilterAndPagination } from 'src/app/models/FilterAndPagination.model';
import { DomainService } from 'src/app/services/domain.service';

@Component({
  selector: 'app-incidents',
  templateUrl: './incidents.component.html',
  styleUrls: ['./incidents.component.css']
})
export class IncidentsComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['id', 'type', 'status', 'terminalId', 'user', 'date', 'resolution', 'resolutionDate', 'accions'];
  dataSource = new MatTableDataSource<Incidence>();
  statuses: string[] = [];
  types: string[] = [];
  totalElements: number = 0;
  pageSize: number = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;
  domainFilter: string = "";

  idFilter = new FormControl();
  typeFilter = new FormControl();
  statusFilter = new FormControl();
  terminalIdFilter = new FormControl();
  userFilter = new FormControl();
  descriptionFilter = new FormControl();
  dateFilter = new FormControl();
  commentFilter = new FormControl();
  resolutionFilter = new FormControl();
  resolutionDateFilter = new FormControl();
  translations: any;

  filterAndPagination: FilterAndPagination = {
    orderDirection: 'DESC',
    orderColumn: 'id', 
    filterGeneric: [],
    page: 0,
    limit: environment.defaultPaginacion,
    pagIndexStart: 0,
    pagIndexEnd: environment.defaultPaginacion
  };

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  constructor(
    private incidenceService: IncidenceService, 
    private utilService: UtilService, 
    private translate: TranslateService, 
    public dialog: MatDialog,
    private domainService: DomainService,
    private cdr: ChangeDetectorRef // Para detectar cambios si es necesario
  ) {}

  ngOnInit(): void {
    // Traducciones
    this.translate
      .get(["INCIDENCE"])
      .subscribe(translation => {
        this.translations = {
          ...translation.INCIDENCE.STATUS,
          ...translation.INCIDENCE.TYPE
        };
      });

    // Cargar datos iniciales
    this.loadIncidence();
    this.loadStatuses();
    this.loadTypes();

    // Suscribirse a los cambios en los filtros
    this.domainService.valueChanged$.subscribe((newValue) => {
      this.domainFilter = newValue; // Update the received value
      this.applyFilters();
    });
    this.idFilter.valueChanges.subscribe(() => this.applyFilters());
    this.typeFilter.valueChanges.subscribe(() => this.applyFilters());
    this.statusFilter.valueChanges.subscribe(() => this.applyFilters());
    this.terminalIdFilter.valueChanges.subscribe(() => this.applyFilters());
    this.userFilter.valueChanges.subscribe(() => this.applyFilters());
    this.descriptionFilter.valueChanges.subscribe(() => this.applyFilters());
    this.dateFilter.valueChanges.subscribe(() => this.applyFilters());
    this.commentFilter.valueChanges.subscribe(() => this.applyFilters());
    this.resolutionFilter.valueChanges.subscribe(() => this.applyFilters());
    this.resolutionDateFilter.valueChanges.subscribe(() => this.applyFilters());
  }

  ngAfterViewInit(): void {
    // Usar ChangeDetectorRef para asegurar que paginator y sort están inicializados
    this.cdr.detectChanges();

    // Asignar paginador y sorter a la dataSource
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // Suscribirse al cambio de ordenación
    this.sort.sortChange.subscribe((sortState: Sort) => {
      this.filterAndPagination.orderColumn = sortState.active;
      this.filterAndPagination.orderDirection = sortState.direction ? sortState.direction.toUpperCase() : 'ASC';
      this.loadIncidence();
    });
  }

  applyFilters(): void {
    const filters = [];

    if (this.idFilter.value) {
      filters.push({ column: 'id', value: this.idFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.typeFilter.value) {
      filters.push({ column: 'type', value: this.typeFilter.value, operation: 'EQUALS', type: 'STRING' });
    }
    if (this.statusFilter.value) {
      filters.push({ column: 'status', value: this.statusFilter.value, operation: 'EQUALS', type: 'STRING' });
    }
    if (this.terminalIdFilter.value) {
      filters.push({ column: 'terminalId', value: this.terminalIdFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.userFilter.value) {
      filters.push({ column: 'user', value: this.userFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.descriptionFilter.value) {
      filters.push({ column: 'description', value: this.descriptionFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.commentFilter.value) {
      filters.push({ column: 'comment', value: this.commentFilter.value, operation: 'LIKE', type: 'STRING' });
    } 
    if (this.dateFilter.value) {
      filters.push({ column: 'date', value: this.utilService.formatDateToISOString(this.dateFilter.value), operation: 'GREATER_THAN_OR_EQUAL', type: 'STRING' });
    }
    if (this.resolutionFilter.value) {
      filters.push({ column: 'resolution', value: this.resolutionFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.resolutionDateFilter.value) {
      filters.push({ column: 'resolutionDate', value: this.utilService.formatDateToISOString(this.resolutionDateFilter.value), operation: 'GREATER_THAN_OR_EQUAL', type: 'STRING' });
    }
    if (this.domainFilter) {
      filters.push({ column: 'domain', value: this.domainFilter, operation: 'LIKE', type: 'STRING' });
    }

    this.filterAndPagination.filterGeneric = filters;
    this.loadIncidence();
  }

  loadIncidence(event?: PageEvent): void {
    this.filterAndPagination.page = event ? event.pageIndex : 0;
    this.filterAndPagination.limit = event ? event.pageSize : this.pageSize;
    this.filterAndPagination.pagIndexStart = this.filterAndPagination.page * this.filterAndPagination.limit;
    this.filterAndPagination.pagIndexEnd = this.filterAndPagination.pagIndexStart + this.filterAndPagination.limit;

    this.incidenceService.findIncidents(this.filterAndPagination).subscribe((page: Page<Incidence>) => {
      this.dataSource.data = page.content;
      this.totalElements = page.totalElements;
      this.pageSize = page.size;

      // Verificar que paginator no sea undefined
      if (this.paginator) {
        this.paginator.length = this.totalElements;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
      }
    });
  }

  loadStatuses(): void {
    this.incidenceService.getStatusIncidents().subscribe((statuses: string[]) => {
      this.statuses = statuses;
    });
  }

  loadTypes(): void {
    this.incidenceService.getTypesIncidents().subscribe((types: string[]) => {
      this.types = types;
    });
  }

  resetDateFilter(): void {
    this.dateFilter.setValue(null);
  }

  resetResolutionDateFilter(): void {
    this.resolutionDateFilter.setValue(null);
  }

  onNew() {
    const dialogRef = this.dialog.open(IncidenceDetailsComponent, {
      width: '70%', height: '80%', panelClass: 'custom-modalbox',
    });

    dialogRef.afterClosed().subscribe(() => {
      this.loadIncidence();
    });
  }

  openDialog(incidence: Incidence): void {
    const dialogRef = this.dialog.open(IncidenceDetailsComponent, {
      width: '70%', height: '80%', panelClass: 'custom-modalbox',
      data: { incidence: incidence }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.loadIncidence();
    });
  }
}
