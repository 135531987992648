

<section class="section">
    <div class="pagetitle pagetitle-modal">
        <h1 i18n>{{"Authorization" | translate }}</h1>  
    </div>
<mat-tab-group [formGroup]="authorizationForm">
    <mat-tab [label]="'Identification' | translate">
        <div class="row ajuste-contenedor-tab">

            <div class="col-lg-6"> 
               <div class="card cardCustos">
                   <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Party ID" | translate }}</h5>
                        <input formControlName="partyId" matInput type="text">
                    </mat-form-field>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Country Code" | translate }}</h5>
                         <input formControlName="countryCode" matInput type="text">
                     </mat-form-field>
                 </div>
             </div>
 
             <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Status" | translate }}</h5>
                         <input formControlName="status" matInput type="text">
                     </mat-form-field>
                 </div>
             </div>

             <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Serial Number" | translate }}</h5>
                         <input formControlName="serialNumber" matInput type="text">
                     </mat-form-field>
                 </div>
             </div>

             <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Message" | translate }}</h5>
                         <input formControlName="message" matInput type="text">
                     </mat-form-field>
                 </div>
             </div> 
            <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Last Updated" | translate }}</h5>
                         <input formControlName="lastUpdated" matInput type="text">
                     </mat-form-field>
                 </div>
             </div>    
           
        </div>

           </mat-tab>
    <mat-tab [label]="'Charging point and Connector' | translate">
        <div class="row ajuste-contenedor-tab">
            <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Location ID" | translate }}</h5>
                         <input formControlName="locationId" matInput type="text">
                     </mat-form-field>
                 </div>
             </div>
            <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Charging point ID" | translate }}</h5>
                         <input formControlName="evseId" matInput type="text">
                     </mat-form-field>
                 </div>
             </div>

             
             <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Connector ID" | translate }}</h5>
                         <input formControlName="connectorId" matInput type="text">
                     </mat-form-field>
                 </div>
             </div>
             <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Language" | translate }}</h5>
                         <input formControlName="language" matInput type="text">
                     </mat-form-field>
                 </div>
             </div>
        </div>
    </mat-tab>
    <mat-tab [label]="'CDR' | translate">
        <div class="row ajuste-contenedor-tab">

            <div class="col-lg-3">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"CDR" | translate }}</h5>
                         <input formControlName="authMethod" matInput type="text">
                     </mat-form-field>
                 </div>
             </div>

        </div>
    </mat-tab>
    <mat-tab [label]="'Command and Session Events' | translate">
        <div class="row ajuste-contenedor-tab">

           <app-timeline [eventAuthorizationId]="this.data.authorization.authorizationReference"></app-timeline>
           
        </div>
    </mat-tab>
  </mat-tab-group>
</section>