import { AfterViewInit, Component, Input, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EVSE } from 'src/app/models/evse.model';
import { environment } from 'src/environments/environment';
import { EvseService } from '../../services/evse.service';
import { Page } from 'src/app/models/page.model';
import { Connector } from 'src/app/models/connector.model';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { EvseConnectorComponent } from '../evse-connector-tariff/evse-connector-tariff.component';
import { TranslateService } from '@ngx-translate/core';
import { FilterAndPagination } from 'src/app/models/FilterAndPagination.model';
import { DomainService } from 'src/app/services/domain.service';

@Component({
  selector: 'app-evse',
  templateUrl: './evse.component.html',
  styleUrls: ['./evse.component.css']
})
export class EvseComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['uid', 'floorLevel', 'status', 'latitude', 'longitude', 'connectors', 'lastUpdated', 'modelType', 'accions'];
  dataSource = new MatTableDataSource<EVSE>();
  statuses: string[] = [];
  modelTypes: string[] = [];

  totalElements: number = 0;
  pageSize: number = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;

  uidFilter = new FormControl();
  floorLevelFilter = new FormControl();
  statusFilter = new FormControl();
  latitudeFilter = new FormControl();
  longitudeFilter = new FormControl();
  modelTypeFilter = new FormControl();
  connectorsFilter = new FormControl();
  lastUpdatedFilter = new FormControl();
  domainFilter: string = "";

  @Input() data?: string[];
  translations: any;

  filterAndPagination: FilterAndPagination = {
    orderDirection: 'DESC',
    orderColumn: 'uid',
    filterGeneric: [],
    page: 0,
    limit: 0,
    pagIndexStart: 0,
    pagIndexEnd: environment.defaultPaginacion
  };

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  constructor(
    private evseService: EvseService,
    public dialog: MatDialog,
    private router: Router,
    private utilService: UtilService,
    private translate: TranslateService,
    private domainService: DomainService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.applyFilters();
    }
    this.loadEvses();
    this.loadStatuses();

    // Suscribirse a los cambios en los filtros
    this.domainService.valueChanged$.subscribe((newValue) => {
      this.domainFilter = newValue; // Update the received value
      this.applyFilters();
    });
    this.uidFilter.valueChanges.subscribe(() => this.applyFilters());
    this.floorLevelFilter.valueChanges.subscribe(() => this.applyFilters());
    this.statusFilter.valueChanges.subscribe(() => this.applyFilters());
    this.latitudeFilter.valueChanges.subscribe(() => this.applyFilters());
    this.modelTypeFilter.valueChanges.subscribe(() => this.applyFilters());
    this.longitudeFilter.valueChanges.subscribe(() => this.applyFilters());
    this.connectorsFilter.valueChanges.subscribe(() => this.applyFilters());
    this.lastUpdatedFilter.valueChanges.subscribe(() => this.applyFilters());

    this.translate.get(['EVSE']).subscribe(translation => {
      this.translations = translation.EVSE.STATUS;
    });
  }

  ngAfterViewInit(): void {
    // Usar ChangeDetectorRef para asegurar que paginator y sort están inicializados
    this.cdr.detectChanges();

    // Asignar paginador y sorter a la dataSource
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.sort.sortChange.subscribe((sortState: Sort) => {
      this.filterAndPagination.orderColumn = sortState.active;
      this.filterAndPagination.orderDirection = sortState.direction ? sortState.direction.toUpperCase() : 'ASC';
      this.loadEvses();
    });
  }

  applyFilters(): void {
    const filters = [];

    if (this.uidFilter.value) {
      filters.push({ column: 'evses.uid', value: this.uidFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.floorLevelFilter.value) {
      filters.push({ column: 'evses.floorLevel', value: this.floorLevelFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.statusFilter.value) {
      filters.push({ column: 'evses.status', value: this.statusFilter.value, operation: 'EQUALS', type: 'STRING' });
    }
    if (this.modelTypeFilter.value) {
      filters.push({ column: 'modelType', value: this.modelTypeFilter.value, operation: 'EQUALS', type: 'STRING' });
    }
    if (this.latitudeFilter.value) {
      filters.push({ column: 'latitude', value: this.latitudeFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.longitudeFilter.value) {
      filters.push({ column: 'longitude', value: this.longitudeFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.connectorsFilter.value) {
      filters.push({ column: 'evses.connectors.connectorId', value: this.connectorsFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.lastUpdatedFilter.value) {
      filters.push({ column: 'evses.lastUpdated', value: this.utilService.formatDateToISOString(this.lastUpdatedFilter.value), operation: 'GREATER_THAN_OR_EQUAL', type: 'DATE' });
    }
    if (this.domainFilter) {
      filters.push({ column: 'domain', value: this.domainFilter, operation: 'LIKE', type: 'STRING' });
    }
    // Filtro adicional si existe terminalData
    if (this.data) {
      filters.push({ column: 'evses.uid', value: this.data.join(','), operation: 'IN', type: 'STRING' });
    }

    this.filterAndPagination.filterGeneric = filters;
    this.loadEvses();
  }



  loadEvses(event?: PageEvent): void {
    this.filterAndPagination.page = event ? event.pageIndex : 0;
    this.filterAndPagination.limit = event ? event.pageSize : this.pageSize;
    this.filterAndPagination.pagIndexStart = this.filterAndPagination.page * this.filterAndPagination.limit;
    this.filterAndPagination.pagIndexEnd = this.filterAndPagination.pagIndexStart + this.filterAndPagination.limit;

    this.evseService.findEvses(this.filterAndPagination).subscribe((page: Page<EVSE>) => {
      this.dataSource.data = page.content;
      this.totalElements = page.totalElements;
      this.pageSize = page.size;

      // Verificar que paginator no sea undefined
      if (this.paginator) {
        this.paginator.length = this.totalElements;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
      }
    });
  }

  openDialog(evse: EVSE): void {
    const dialogRef = this.dialog.open(EvseConnectorComponent, {
      width: '70%', height: '80%', panelClass: 'custom-modalbox',
      data: evse 
    });

    dialogRef.afterClosed().subscribe(() => {
      this.loadEvses();
    });
  }

  loadStatuses(): void {
    this.evseService.getStatusEvses().subscribe((statuses: string[]) => {
      this.statuses = statuses;
    });
  }

  getConnectorIds(connectors: Connector[]): string {
    return connectors.map(connector => connector.connectorId).join(', ');
  }

  navigateToLocations(locationId: string) {
    this.router.navigate([`/location/${locationId}`]);
  }

  resetLastUpdatedFilter(): void {
    this.lastUpdatedFilter.setValue(null);
  }

  
}
