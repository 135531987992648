
<div class="pagetitle pagetitle-modal">
    <h1 i18n>{{"Terminal Details" | translate }}</h1>  
</div>
<section class="section">

<mat-tab-group >
    <mat-tab [label]="'Charging point list' | translate">
        <div class="row ajuste-contenedor-tab">
            <app-evse  [data]="this.evsesIds"></app-evse>
        </div>
    </mat-tab>
  </mat-tab-group>
</section>