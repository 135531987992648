<main id="main" class="main">


    <div class="pagetitle">
      <h1 i18n>{{"Client" | translate }}</h1>  
      <div>
        <button mat-mini-fab (click)="onNew()"> 
         <i class="bi bi-plus-lg"></i>
        </button>
      </div>
  </div>
    
    
      
      <div class="mat-elevation-z8 tablaPersonal">
        <mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="partyId">
              <mat-header-cell *matHeaderCellDef class="header-table">
                <div mat-sort-header i18n>{{"Party ID" | translate }}</div>  
                <div>
                  <mat-form-field>
                    <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                    <input matInput [formControl]="partyIdFilter" placeholder="" maxlength="40">
                  </mat-form-field>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let client">{{client.partyId}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="countryCode">
              <mat-header-cell *matHeaderCellDef class="header-table">
                <div mat-sort-header i18n>{{"Country Code" | translate }}</div>  
                <div>
                  <mat-form-field>
                    <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                    <input matInput [formControl]="countryCodeFilter" placeholder="" maxlength="40">
                  </mat-form-field>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let client">{{client.countryCode}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="role">
              <mat-header-cell *matHeaderCellDef class="header-table">
                <div mat-sort-header i18n>{{"Role" | translate }}</div>  
                <div>
                  <mat-form-field>
                    <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                    <mat-select [formControl]="roleFilter" placeholder="">
                      <mat-option value=""></mat-option>
                      <mat-option *ngFor="let role of roles" [value]="role">{{  translations[role] }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let client">{{ translations[client.role]}}</mat-cell>
            </ng-container>
          
            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef class="header-table">
                <div mat-sort-header i18n>{{"Status" | translate }}</div>  
                <div>
                  <mat-form-field>
                    <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                    <mat-select [formControl]="statusFilter" placeholder="">
                      <mat-option value=""></mat-option>
                      <mat-option *ngFor="let status of statuses" [value]="status">{{  translations[status] }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let client" class="DEFAULT {{client.status}} "> <div>{{ translations[client.status]}} </div></mat-cell>
            </ng-container>
          
            <ng-container matColumnDef="lastUpdated">
              <mat-header-cell *matHeaderCellDef class="header-table">
                <div mat-sort-header i18n>{{"Last Updated" | translate }}</div>
                <div>
                  <mat-form-field>
                    <mat-icon matPrefix><i class="bi bi-filter"></i></mat-icon>
                    <input matInput [matDatepicker]="picker" [formControl]="lastUpdatedFilter" placeholder="">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <button mat-icon-button matSuffix *ngIf="lastUpdatedFilter.value" (click)="resetLastUpdatedFilter()">
                      <mat-icon>clear</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.lastUpdated | date}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef >
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <button (click)="openDialog(element)" mat-icon-button color="primary">
                  <i class="bi bi-journal-text"></i>
                  </button>
              </mat-cell>
            </ng-container>
            

            <!-- Definir el header y rows -->
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
          
          <!-- Paginador -->
          <mat-paginator [length]="totalElements"
                         [pageSize]="pageSize"
                         [pageSizeOptions]="pageSizeOptions"
                         (page)="loadClients($event)">
          </mat-paginator>
      </div>
      </main>



  