import { AfterViewInit, Component, Input, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import { Page } from 'src/app/models/page.model';
import { ConnectorService } from '../../services/connector.service';
import { Connector } from 'src/app/models/connector.model';
import { ConnectorTariffsComponent } from '../connector-tariffs/connector-tariffs.component';
import { MatDialog } from '@angular/material/dialog';
import { EVSE } from 'src/app/models/evse.model';
import { UtilService } from 'src/app/services/util.service';
import { TranslateService } from '@ngx-translate/core';
import { FilterAndPagination } from 'src/app/models/FilterAndPagination.model';
import { DomainService } from 'src/app/services/domain.service';

@Component({
  selector: 'app-connector',
  templateUrl: './connector.component.html',
  styleUrls: ['./connector.component.css']
})
export class ConnectorComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['connectorId', 'standard', 'powerType', 'connectorFormat', 'maxAmperage', 'maxVoltage', 'lastUpdated', 'accions'];
  dataSource = new MatTableDataSource<Connector>();
  connectorFormats: string[] = [];
  connectorTypes: string[] = [];
  powerTypes: string[] = [];
  modelTypes: string[] = [];
  translations: any;

  totalElements: number = 0;
  pageSize: number = environment.defaultPaginacion;
  pageSizeOptions = environment.paginacion;

  connectorIdFilter = new FormControl();
  standardFilter = new FormControl();
  powerTypeFilter = new FormControl();
  connectorFormatFilter = new FormControl();
  maxAmperageFilter = new FormControl();
  maxVoltageFilter = new FormControl();
  lastUpdatedFilter = new FormControl();
  domainFilter: string = "";

  @Input() data?: string[];

  filterAndPagination: FilterAndPagination = {
    orderDirection: 'DESC',
    orderColumn: 'connectorId',
    filterGeneric: [],
    page: 0,
    limit: 0,
    pagIndexStart: 0,
    pagIndexEnd: environment.defaultPaginacion
  };

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  constructor(
    private connectorService: ConnectorService,
    public dialog: MatDialog,
    private utilService: UtilService,
    private translate: TranslateService,
    private domainService: DomainService,
    private cdr: ChangeDetectorRef // Para detectar cambios si es necesario
  ) {}

  ngOnInit(): void {
    this.translate.get(["CONNECTOR"]).subscribe(translation => {
      this.translations = translation.CONNECTOR.FORMAT;
    });

    if (this.data) {
      this.applyFilters();
    }
    this.loadConnectors();
    this.loadConnectorFormats();
    this.loadConnectorTypes();
    this.loadPowerTypes();

    // Suscribirse a los cambios en el FormControl
    this.domainService.valueChanged$.subscribe((newValue) => {
      this.domainFilter = newValue; // Update the received value
      this.applyFilters();
    });
    this.connectorIdFilter.valueChanges.subscribe(() => this.applyFilters());
    this.standardFilter.valueChanges.subscribe(() => this.applyFilters());
    this.powerTypeFilter.valueChanges.subscribe(() => this.applyFilters());
    this.connectorFormatFilter.valueChanges.subscribe(() => this.applyFilters());
    this.maxAmperageFilter.valueChanges.subscribe(() => this.applyFilters());
    this.maxVoltageFilter.valueChanges.subscribe(() => this.applyFilters());
    this.lastUpdatedFilter.valueChanges.subscribe(() => this.applyFilters());
  }

  ngAfterViewInit(): void {
    // Usar ChangeDetectorRef para asegurar que paginator y sort están inicializados
    this.cdr.detectChanges();

    // Asignar paginador y sorter a la dataSource
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // Ordenación
    this.sort.sortChange.subscribe((sortState: Sort) => {
      this.filterAndPagination.orderColumn = sortState.active;
      this.filterAndPagination.orderDirection = sortState.direction ? sortState.direction.toUpperCase() : 'ASC';
      this.loadConnectors();
    });
  }

  applyFilters(): void {
    const filters = [];

    if (this.connectorIdFilter.value) {
      filters.push({ column: 'evses.connectors.connectorId', value: this.connectorIdFilter.value, operation: 'LIKE', type: 'STRING' });
    }
    if (this.standardFilter.value) {
      filters.push({ column: 'evses.connectors.standard', value: this.standardFilter.value, operation: 'EQUALS', type: 'STRING' });
    }
    if (this.powerTypeFilter.value) {
      filters.push({ column: 'evses.connectors.powerType', value: this.powerTypeFilter.value, operation: 'EQUALS', type: 'STRING' });
    }
    if (this.connectorFormatFilter.value) {
      filters.push({ column: 'evses.connectors.format', value: this.connectorFormatFilter.value, operation: 'EQUALS', type: 'STRING' });
    }
    if (this.maxAmperageFilter.value) {
      filters.push({ column: 'evses.connectors.maxAmperage', value: this.maxAmperageFilter.value, operation: 'EQUALS', type: 'INTEGER' });
    }
    if (this.maxVoltageFilter.value) {
      filters.push({ column: 'evses.connectors.maxVoltage', value: this.maxVoltageFilter.value, operation: 'EQUALS', type: 'INTEGER' });
    }
    if (this.lastUpdatedFilter.value) {
      filters.push({ column: 'evses.connectors.lastUpdated', value: this.utilService.formatDateToISOString(this.lastUpdatedFilter.value), operation: 'GREATER_THAN_OR_EQUAL', type: 'DATE' });
    }
    if (this.domainFilter) {
      filters.push({ column: 'domain', value: this.domainFilter, operation: 'LIKE', type: 'STRING' });
    }

    // Filtro adicional si existe evseData
    if (this.data) {
      filters.push({ column: 'evses.connectors.connectorId', value: this.data.join(','), operation: 'IN', type: 'STRING' });
    }

    this.filterAndPagination.filterGeneric = filters;

    this.loadConnectors();
  }

  loadConnectors(event?: PageEvent): void {
    this.filterAndPagination.page = event ? event.pageIndex : 0;
    this.filterAndPagination.limit = event ? event.pageSize : this.pageSize;
    this.filterAndPagination.pagIndexStart = this.filterAndPagination.page * this.filterAndPagination.limit;
    this.filterAndPagination.pagIndexEnd = this.filterAndPagination.pagIndexStart + this.filterAndPagination.limit;

    this.connectorService.findConnectors(this.filterAndPagination).subscribe((page: Page<Connector>) => {
      this.dataSource.data = page.content;
      this.totalElements = page.totalElements;
      this.pageSize = page.size;

      // Verificar que paginator no sea undefined
      if (this.paginator) {
        this.paginator.length = this.totalElements;
        this.paginator.pageIndex = page.number;
        this.paginator.pageSize = page.size;
      }
    });
  }

  loadConnectorFormats(): void {
    this.connectorService.getConnectorFormats().subscribe((connectorFormats: string[]) => {
      this.connectorFormats = connectorFormats;
    });
  }

  loadConnectorTypes(): void {
    this.connectorService.getConnectorTypes().subscribe((connectorTypes: string[]) => {
      this.connectorTypes = connectorTypes;
    });
  }

  loadPowerTypes(): void {
    this.connectorService.getPowerTypes().subscribe((powerTypes: string[]) => {
      this.powerTypes = powerTypes;
    });
  }

  openDialog(connector: Connector): void {
    const dialogRef = this.dialog.open(ConnectorTariffsComponent, {
      width: '70%', height: '80%', panelClass: 'custom-modalbox',
      data: connector 
    });

    dialogRef.afterClosed().subscribe(() => {
      this.loadConnectors();
    });
  }

  getAllConnectorIds(evse: EVSE): string[] {
    return evse.connectors.map(connector => connector.connectorId);
  }

  resetLastUpdatedFilter(): void {
    this.lastUpdatedFilter.setValue(null);
  }

}
