
<section class="section">
    <div class="pagetitle pagetitle-modal">
        <h1 i18n>{{"Clients" | translate }}</h1>  
    </div>
<mat-tab-group [formGroup]="clientForm">
    <mat-tab [label]="ban ? ('New client' | translate) : ('Edit client' | translate)" label="">
      <div class="row ajuste-contenedor-tab">
        <div class="col-lg-6"> 
            <div class="card cardCustos">
                <mat-form-field ngDefaultControl>
                     <h5 i18n>{{"Party ID" | translate }}</h5>
                     <input formControlName="partyId" matInput type="text">
                     <mat-error *ngIf="clientForm.get('partyId')?.hasError('required')">
                       {{"party ID is required." | translate }} 
                     </mat-error>
                     <mat-error *ngIf="clientForm.get('partyId')?.hasError('minlength') || clientForm.get('partyId')?.hasError('maxlength')">
                      {{"Party ID must be exactly 3 characters." | translate }} 
                    </mat-error>
                 </mat-form-field>
             </div>
         </div>


            <div class="col-lg-6"> 
               <div class="card cardCustos">
                   <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Country code" | translate }}</h5>
                        <input formControlName="countryCode" matInput type="text">
                        <mat-error *ngIf="clientForm.get('countryCode')?.hasError('required')">
                          {{"Country code is required." | translate }} 
                        </mat-error>
                        <mat-error *ngIf="clientForm.get('countryCode')?.hasError('minlength') || clientForm.get('countryCode')?.hasError('maxlength')">
                          {{"Country code must be exactly 2 characters." | translate }} 
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="card cardCustos">
                  <mat-form-field ngDefaultControl>
                    <h5 i18n>{{"Status" | translate }}</h5>
                    <mat-select formControlName="status">
                      <mat-option *ngFor="let status of statuses" [value]="status">
                        {{ translations[status] }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="clientForm.get('status')?.hasError('required')">
                      {{"Status is required." | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              
 
              <div class="col-lg-6">
                <div class="card cardCustos">
                  <mat-form-field ngDefaultControl>
                    <h5 i18n>{{"Role" | translate }}</h5>
                    <mat-select formControlName="role">
                      <mat-option *ngFor="let role of roles" [value]="role">
                        {{ translations[role] }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="clientForm.get('role')?.hasError('required')">
                      {{"Role is required." | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
           
        </div>

    </mat-tab>
  </mat-tab-group>

    <div style=" margin: 2em">
            <button  class="btn btn-aceptar" (click)="onFormSubmit()" i18n>{{"Submit" | translate }}</button> 
            <button  class="btn btn-cancelar" (click)="onClose()" i18n>{{"Cancel" | translate }}</button>
    </div>

</section>