
<section class="section">
    <div class="pagetitle pagetitle-modal">
        <h1 i18n>{{"Add Terminal" | translate }} </h1>  
    </div>
<mat-tab-group [formGroup]="terminalForm" [(selectedIndex)]="selectedTabIndex">
    <mat-tab [label]="'Select locations' | translate" [disabled]="isTabDisabled(0)">
        <div class="row ajuste-contenedor-tab">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card cardCustos">
                        <app-dual-list [filter]="true" [sort]="true" [key]="keyDualList"  [display]="displayDualList" [format]="formartDualList" 
                        [source]="locations" [(destination)]="listaResultSelect" height="350px"></app-dual-list>
                    </div>
                </div>
            </div>
        </div>
    <div style=" margin: 2em">
      <button  class="btn btn-aceptar" (click)="onFormSubmitLocations()" i18n>{{"Proceed" | translate }}</button> 
      <button  class="btn btn-cancelar" (click)="onClose()" i18n>{{"Cancel" | translate }}</button>

    </div>

    </mat-tab>
    <mat-tab [label]="'Select charging points' | translate" [disabled]="isTabDisabled(1)">
        <div class="row ajuste-contenedor-tab">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card cardCustos">
                        <app-dual-list [filter]="true" [sort]="true" [key]="keyDualListEvse"  [display]="displayDualListEvse" [format]="formartDualList" 
                        [source]="evses" [(destination)]="listaResultSelectEvse" height="350px"></app-dual-list>
                    </div>
                </div>
            </div>
        </div>
        <div style=" margin: 2em">
          <button  class="btn btn-aceptar" (click)="onFormSubmitEvses()" i18n>{{"Proceed" | translate }}</button> 
          <button  class="btn btn-cancelar" (click)="onBackLocations()" i18n>{{"Back" | translate }}</button>
        </div>
    </mat-tab>
     <mat-tab [label]="'Finish' | translate"  [disabled]="isTabDisabled(2)">
       <div class="row ajuste-contenedor-tab">
                <div class="col-lg-6">
                    <div class="card cardCustos">
                        <mat-form-field ngDefaultControl>
                             <h5 i18n>{{"Preauthorization Amount" | translate }}</h5>
                             <input formControlName="preAuthorizationAmount" matInput type="text">
                             <mat-hint i18n>{{"By default, it will be a value of €50." | translate }}</mat-hint>
                             <mat-error *ngIf="terminalForm.get('preAuthorizationAmount')?.hasError('pattern')" i18n>
                              {{"Only numbers with up to two decimal places are allowed." | translate }}
                            </mat-error>
                         </mat-form-field>
                     </div>
                 </div>

                 <div class="col-lg-6">
                  <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                      <h5 i18n> {{"Status" | translate }} </h5>
                      <mat-select formControlName="status">
                        <mat-option *ngFor="let status of statusList" [value]="status">
                          {{translations[status]}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                  <div class="col-lg-6">
                    <div class="card cardCustos">
                      <div class="pagetitle">
                        <h3 i18n>  {{"Location list" | translate }}</h3>
                      </div>
                      <mat-list class="custom-list"> 
                        <mat-list-item *ngFor="let location of listaResultSelect">
                          {{ location.locationId }}
                        </mat-list-item>
                      </mat-list>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="card cardCustos">
                      <div class="pagetitle">
                        <h3 i18n>  {{"Charging point list" | translate }}</h3>
                      </div>
                      <mat-list class="custom-list">
                        <mat-list-item *ngFor="let evse of listaResultSelectEvse">
                          {{ evse.evseId }}
                        </mat-list-item>
                      </mat-list>
                    </div>
                  </div>
                  


        </div>
        <div style=" margin: 2em">
          <button  class="btn btn-aceptar" (click)="onFormSubmit()" i18n>{{"Proceed" | translate }}</button> 
          <button  class="btn btn-cancelar" (click)="onBackEvses()"i18n>{{"Back" | translate }}</button>
        </div>
    </mat-tab>
  </mat-tab-group>

</section>