import { Component, OnInit } from '@angular/core';
import { Page } from 'src/app/models/page.model';
import { Location } from 'src/app/models/location.model';
import { LocationService } from '../../services/location.service';
import { Coordinates } from 'src/app/models/coordinates.model';
import { ActivatedRoute } from '@angular/router';
import { FilterAndPagination } from 'src/app/models/FilterAndPagination.model';
import { DomainService } from 'src/app/services/domain.service';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css']
})
export class LocationsComponent implements OnInit {
  locations: Location[] = [];
  coordinates: Coordinates[] = [];
  domainFilter: string = "";

  locationData: string | undefined;

  constructor(private locationService: LocationService,    
    private domainService: DomainService,
    private activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {
      this.domainService.valueChanged$.subscribe((newValue) => {
        this.domainFilter = newValue; // Update the received value
        this.loadLocations();
      });
      // Obtener el parámetro `locationId` solo si está presente
      this.activatedRoute.paramMap.subscribe(params => {
        this.locationData = params.get('locationId') ?? undefined;
        this.loadLocations(this.locationData);
      });
    }
  

  loadLocations(locationId?: string): void {
    this.coordinates = [];

    let  requestParams: FilterAndPagination = {
      orderDirection: '',
      orderColumn: '', 
      filterGeneric: [{
        column: 'domain',
        value: this.domainFilter,
        operation: 'LIKE',
        type: 'STRING'
      }],
      page: 0,
      limit: 100,
      pagIndexStart: 0,
      pagIndexEnd: 100
    };
    
    // Si hay un `locationId`, agregarlo a los filtros
    if (locationId) {
      requestParams.filterGeneric.push({
        column: 'locationId',
        value: locationId,
        operation: 'EQUALS',
        type: 'STRING'
      });
    }
  
    this.locationService.findLocations(requestParams).subscribe((page: Page<Location>) => {
      this.locations = page.content;
      this.dataTransformer(); 
    });
  }

  dataTransformer(): void {
    
    this.locations.forEach(location => {
      this.coordinates.push({
        lat: parseFloat(location.latitude),
        lng: parseFloat(location.longitude),
        label: location.locationId + ' - ' + location.city,
        type: "location"
      });
  
      if (location.evses && location.evses.length > 0) {
        location.evses.forEach(evse => {
          this.coordinates.push({
            lat: parseFloat(location.latitude), 
            lng: parseFloat(location.longitude), 
            label: evse.evseId + ' - N:' + evse.floorLevel ,
            type: "evse"

          });
        });
      }
    });
      }
}
