import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent{
  isDefault: boolean = true;
 

  
  constructor(@Inject(DOCUMENT) document: any) {
    // let dominio = document.location.href;
  }

}
