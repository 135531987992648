import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, shareReplay } from 'rxjs';
import { Tariff } from 'src/app/models/tariffs.model';
import { Page } from 'src/app/models/page.model';
import { handleError } from 'src/app/shared/util/handelError';
import { environment } from 'src/environments/environment';
import { FilterAndPagination } from 'src/app/models/FilterAndPagination.model';


@Injectable({
  providedIn: 'root',
})
export class TariffService {

  private typestariffs$!: Observable<string[]>;
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
    this.initialize();
  }

  findTariffs(filterAndPagination: FilterAndPagination): Observable<Page<Tariff>> {
    return this.http.post<Page<Tariff>>(`${this.apiUrl}/tariff`, filterAndPagination)
      .pipe(
        catchError(handleError<Page<Tariff>>('findtariffs', { content: [], totalPages: 0, totalElements: 0, number: 0, size: 0 }))
      );
  }


  private initialize(): void {
    this.typestariffs$ = this.http.get<string[]>(`${this.apiUrl}/tariff/tariff-types`)
      .pipe(
        catchError(handleError<string[]>('fetching tariff types', [])),
        shareReplay(1)
      );
  }

  getTypesTariffs(): Observable<string[]> {
    return this.typestariffs$;
  }
}
