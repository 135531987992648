<main>
   

   <div *ngIf="isDefault" class="grid-block" style="background-color: #e4effb; background-image: url('assets/img/welcome.svg'); width: 100%; height: 100vh; background-position:center center;background-repeat: no-repeat;"></div>
   
   
   
   <div class="container">
      

     
 
   </div>
</main>
