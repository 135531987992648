import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, shareReplay } from 'rxjs';
import { Page } from 'src/app/models/page.model';
import { Incidence } from 'src/app/models/incidence.model';
import { handleError } from 'src/app/shared/util/handelError';
import { environment } from 'src/environments/environment';
import { FilterAndPagination } from 'src/app/models/FilterAndPagination.model';


@Injectable({
  providedIn: 'root',
})
export class IncidenceService { 

  private statusIncidents$!: Observable<string[]>;
  private typesIncidents$!: Observable<string[]>;

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
    this.initialize();
  }


  findIncidents(filterAndPagination: FilterAndPagination): Observable<Page<Incidence>> {
    return this.http.post<Page<Incidence>>(`${this.apiUrl}/incidence`, filterAndPagination)
      .pipe(
        catchError(handleError<Page<Incidence>>('findIncidents', { content: [], totalPages: 0, totalElements: 0, number: 0, size: 0 }))
      );
  }

  saveIncidence(incidentRequest: Incidence): Observable<Incidence> {
    return this.http.post<Incidence>(`${this.apiUrl}/incidence/save`, incidentRequest)
      .pipe(
        catchError(handleError<Incidence>('saveIncidence'))
      );
  }

  private initialize(): void {
    this.statusIncidents$ = this.http.get<string[]>(`${this.apiUrl}/incidence/status`)
      .pipe(
        catchError(handleError<string[]>('fetching incidence status', [])),
        shareReplay(1)
      );

      this.typesIncidents$ = this.http.get<string[]>(`${this.apiUrl}/incidence/types`)
      .pipe(
        catchError(handleError<string[]>('fetching incidence types', [])),
        shareReplay(1)
      );
   
  } 

  getStatusIncidents(): Observable<string[]> {
    return this.statusIncidents$;
  }

  getTypesIncidents(): Observable<string[]> {
    return this.typesIncidents$;
  }
}
